import { requestGet } from "../utils/dva16";
import {
	NTeam,
	EGet,
	EGetCollect,
	EGetOne,
	RSetState,
	EGetType,
} from "./contants";

const Team = {
	namespace: NTeam,
	state: {
		loading: false,
		TeamList: null,
		TeamTitle: null,
		TeamCollect: null,
		esportTeamSearch: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("esportTeam", payload);
			reducer(RSetState, { TeamList: res, loading: false });
		},
		async [EGetCollect](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("esportTeamAnalysis", payload);
			reducer(RSetState, { TeamCollect: res, loading: false });
		},
		async [EGetType]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("esportTeamSearch", payload);
			reducer(RSetState, { esportTeamSearch: res });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("esportTeamTitle", payload);
			res.Region = res.Region?.map((vo) => {
				if (Array.isArray(vo?.region)) {
					if (vo?.region?.length > 1) {
						vo.region = "Global";
					} else {
						vo.region = vo?.region[0];
					}
				}
				return vo;
			});
			reducer(RSetState, { TeamTitle: res });
		},
	},
};
export default Team;
