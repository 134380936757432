import { requestGet } from "../utils/dva16";
import { NGenre, EGet, RSetState } from "./contants";

const Genre = {
	namespace: NGenre,
	state: {
		loading: false,
		GenreList: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("genre", payload);
			reducer(RSetState, { GenreList: res, loading: false });
		},
	},
};
export default Genre;
