import React, { useEffect, useState, useRef } from "react";
import {
	Layout,
	Menu,
	Input,
	Breadcrumb,
	Dropdown,
	Typography,
	Tooltip,
	message,
	Divider,
	Row,
	Col,
} from "antd";
import { withRouter } from "react-router-dom";
import "./styles.less";
import routes from "../routes";
import { intersection, filter } from "lodash";
import { useStore, reducer, effect } from "../utils/dva16";
import userIcon from "../assets/icons8-user-64.png";
import {
	NGlobal,
	RSetState,
	NLogin,
	EGet,
	EGetCommonArea,
	NRegion,
	AContact,
	env,
	EPost,
	MName,
	EGetCurrentSubscription,
	EGetLatestModules,
} from "../models/contants";
import { UserOutlined, PoweroffOutlined } from "@ant-design/icons";
import imgSource from "../assets";
import breadcrumbName from "./breadcrumb";
import { setToken } from "../utils/utils";
import { useHistory } from "react-router";
import { Footer } from "antd/lib/layout/layout";
import { useMediaQuery } from "react-responsive";
import TooltipStyler from "./components/toolTipStyler";
import NewContact from "./components/contact";
const { Link } = Typography;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Search } = Input;
const showEnv = ["DEV", "UAT"];

//查找父节点 | Find Parent Node
const onFindParentNode = (routes, path) => {
	for (const item of routes) {
		if (item?.path === path) {
			return { selectedKeys: [item?.path], openKeys: [item?.path] };
		} else {
			if (item?.routes) {
				for (const children of item?.routes) {
					let result = intersection(
						children?.path?.split("/"),
						path?.split("/")
					);
					if (result?.length > 2) {
						return { selectedKeys: [children?.path], openKeys: [item?.path] };
					}
				}
			}
		}
	}
};

function BasicLayout(props) {
	const [hoveredParent, setHoveredParent] = useState(null);
	const { currentEnivronment, load } = useStore(env);

	useEffect(() => {
		effect(env, EGet);
		effect(NLogin, EGet, {});
		effect(NLogin, EGetCurrentSubscription, {});
		effect(NLogin, EGetLatestModules, {});

		return () => {};
	}, []);
	const {
		location: { pathname },
		children,
	} = props;
	const history = useHistory();
	const refHeader = useRef(null);
	const { collapsed } = useStore(NGlobal);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [pointerClass, setPointerClass] = useState(false);
	const [contactView, setContactView] = useState(false);
	const [openKey, setOpenKey] = useState([]);
	const [height, setHeight] = useState(0);
	const [breadcrumb, setBreadcrumb] = useState([]);
	const [contactForm, setContactForm] = useState({});
	const breadcrumbClass =
		breadcrumb?.length === 2 ? "breadcrumb-two" : "breadcrumb-three";
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
	const { modelName } = useStore(MName);
	const onNewContact = () => {
		setContactView(true);
	};
	function handleClick() {
		window.scrollTo(0, 0);
	}
	const toDetails = (value) => {
		history.push({
			pathname: "/Search",
			search: `keyword=${value}`,
		});
	};
	const nav = (value) => {
		let nav = false;

		function findRoutePath(routes, value) {
			for (const route of routes) {
				if (route?.name === value) {
					if (route?.routes && route?.routes?.length > 0) {
						nav = false;
						return null;
					} else {
						nav = true;
						return route.path;
					}
				}
				if (route?.routes && route?.routes?.length > 0) {
					const nestedPath = findRoutePath(route?.routes, value);
					if (nestedPath) {
						return nestedPath;
					}
				}
			}
			return null;
		}

		let navLocation = findRoutePath(routes, value);
		if (nav === true) {
			setPointerClass(true);
			history.push({
				pathname: navLocation,
			});
		}
	};
	const nav2 = (value) => {
		function findRoutePath(routes, value) {
			for (const route of routes) {
				if (route?.menuName !== "Games & Organizations") {
					if (route?.name === value) {
						// Return the route if it matches the value
						return route;
					}
					if (route?.routes && route?.routes?.length > 0) {
						// Recursively search in nested routes
						const nestedRoute = findRoutePath(route?.routes, value);
						if (nestedRoute) {
							return nestedRoute;
						}
					}
				}
				{
					if (route?.menuName === "Games & Organizations") {
						// Return the route if it matches the value
						return route;
					}
					if (route?.routes && route?.routes?.length > 0) {
						// Recursively search in nested routes
						const nestedRoute = findRoutePath(route?.routes, value);
						if (nestedRoute) {
							return nestedRoute;
						}
					}
				}
			}
			// Return null if no matching route is found
			return null;
		}

		let navLocation = findRoutePath(routes, value);
		return navLocation; // Return the found route or null if not found
	};

	useEffect(() => {
		let nav = false;

		function findRoutePath(routes) {
			for (const route of routes) {
				if (route?.path === pathname) {
					if (route?.routes && route?.routes?.length > 0) {
						nav = false;
						return null;
					} else {
						nav = true;
						return route.path;
					}
				}
				if (route?.routes && route?.routes?.length > 0) {
					const nestedPath = findRoutePath(route?.routes);
					if (nestedPath) {
						return nestedPath;
					}
				}
			}
			return null;
		}

		let navLocation = findRoutePath(routes);

		if (nav === true) {
			setPointerClass(true);
		}
	}, [pathname]);

	useEffect(() => {
		setHeight(refHeader?.current?.clientHeight);
	}, []);
	useEffect(() => {
		effect(NRegion, EGetCommonArea, { page: 1, perPage: 100 });
	}, []);
	//根据pathname查找对应的展开项 | Find the corresponding expansion item based on pathname
	useEffect(() => {
		if (pathname) {
			let res = onFindParentNode(routes, pathname);
			if (res) {
				let { selectedKeys, openKeys } = res;

				setOpenKey(openKeys);
				setSelectedKeys([...selectedKeys]);
			}
		}
		let breadcrumbList = filter(pathname?.split("/"), (text) => {
			if (text && isNaN(text)) return text;
		});

		setBreadcrumb(breadcrumbList);
	}, [pathname]);

	//左侧导航栏展开或收起 | Expand or collapse the left navigation bar
	const onCollapse = () => {
		reducer(NGlobal, RSetState, { collapsed: !collapsed });
	};

	//登录状态框 | Login Status Box
	const items = [
		{
			key: "userInfo",
			label: (
				<div
					onClick={() => {
						history.replace("/user/user_profile");
					}}
				>
					User Profile
				</div>
			),
		},
		{
			key: "loginOut",
			label: (
				<div
					onClick={() => {
						setToken("");
						history.replace("/login");
					}}
				>
					Log out
				</div>
			),
		},
	];
	const [tooltipVisible, setTooltipVisible] = useState(false);

	const handleMouseEnter = () => {
		setTooltipVisible(true);
	};

	const handleMouseLeave = () => {
		setTooltipVisible(false);
	};
	return (
		<Layout className="layout_container" style={{ paddingTop: `${height}px` }}>
			<div>
				{" "}
				{showEnv?.includes(currentEnivronment?.env) && (
					<h2
						style={{
							marginTop: "5px",
							width: "100%",
							textAlign: "center",
						}}
					>
						{`${currentEnivronment?.env} : ${currentEnivronment?.build}`}
					</h2>
				)}
			</div>
			<Header>
				<div className="layout_page_header" ref={refHeader}>
					<div className="header_rows left_content" id="img">
						<Link href="#/home">
							<img
								className="logo"
								src={imgSource?.logoNiko}
								style={{ objectFit: "contain" }}
							/>
						</Link>

						{/* <Breadcrumb>
							{breadcrumb?.map((item, index) => {
								let active = index === breadcrumb?.length - 1;
								return (
									<Breadcrumb.Item
										key={index}
										className={`breadcrumb_item ${
											active ? "breadcrumb_item_active" : ""
										}`}
									>
										{item == "game_details_paid_sample" ||
										item == "organization_detail" ||
										item == "tournament_detail" ||
										item == "details" ? (
											<span class="truncate">{modelName}</span>
										) : (
											<span
												onClick={() => nav(breadcrumbName[item])}
												style={
													pointerClass &&
													(!nav2(breadcrumbName[item])?.routes ||
														breadcrumbName[item] == "Organizations" ||
														breadcrumbName[item] == "Games") &&
													breadcrumb[breadcrumb?.length - 1] !=
														Object.keys(breadcrumbName).find(
															(key) =>
																breadcrumbName[key] === breadcrumbName[item]
														)
														? { cursor: "pointer" }
														: { cursor: "default" }
												}
												class={"truncate2"}
											>
												{breadcrumbName[item]}
											</span>
										)}
									</Breadcrumb.Item>
								);
							})}
						</Breadcrumb> */}
					</div>
					<div className="header_rows">
						<Dropdown
							menu={{
								items,
							}}
						>
							<div className="login_con">
								<span className="login_user_name">{userInfo?.username}</span>
								<img
									style={{ width: "40px", height: "40px" }}
									src={require("@/assets/images/user_head_pic.png")?.default}
								/>
							</div>
						</Dropdown>
					</div>
				</div>
			</Header>
			<Layout className="layout_main">
				<Sider
					collapsible={isSmallScreen ? false : true}
					collapsed={isSmallScreen ? true : collapsed}
					width={220}
					collapsedWidth={60}
					onCollapse={onCollapse}
				>
					{tooltipVisible && <TooltipStyler />}
					<Menu
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						style={{ minWidth: 72, padding: "10px", marginTop: "1rem" }}
						mode="inline"
						selectedKeys={selectedKeys}
						openKeys={openKey}
						onClick={({ key }) => {
							history.push(key);
							setHoveredParent(key); // Set hovered parent when child is clicked
						}}
						onOpenChange={(openKeys) => {
							setOpenKey(openKeys?.concat());
						}}
					>
						{routes?.map((item, index) => {
							if (item?.routes) {
								if (
									userInfo?.type === "Admin" ||
									(currentSubscribe &&
										item?.menuName === "Games & Organizations" &&
										(currentSubscribe?.module["Games"]?.length > 0 ||
											currentSubscribe?.module["Organizations"]?.length > 0))
								) {
									let subFlag = item?.routes?.find((v) => {
										if (v?.path === selectedKeys[0]) {
											return v;
										}
									});
									return (
										!item?.hideInMenu && (
											<SubMenu
												selectedKeys={selectedKeys}
												key={item?.path}
												title={item?.name}
												onClick={({ key }) => {
													history.push(key);
													setHoveredParent(key); // Set hovered parent when child is clicked
												}}
												icon={
													item?.icon && (
														<img
															className="subMenu_icon"
															src={item?.icon}
															style={{ width: "22px", height: "22px" }}
															alt=""
														/>
													)
												}
											>
												{item?.routes?.map((vo, key) => {
													if (
														(currentSubscribe &&
															currentSubscribe?.module[vo?.menuName]?.length >
																0) ||
														userInfo?.type === "Admin"
													) {
														return (
															!vo?.hideInMenu && (
																<Menu.Item
																	key={vo?.path}
																	onClick={({ key }) => {
																		history.push(key);
																		setHoveredParent(key); // Set hovered parent when child is clicked
																	}}
																	icon={
																		vo?.icon && (
																			<img
																				style={{
																					width: "22px",
																					height: "22px",
																				}}
																				src={vo?.icon}
																				alt=""
																			/>
																		)
																	}
																>
																	{vo?.name}
																</Menu.Item>
															)
														);
													}
												})}
											</SubMenu>
										)
									);
								}
								if (
									userInfo?.type === "Admin" ||
									(currentSubscribe &&
										item?.menuName === "Games & Organizations" &&
										(currentSubscribe?.module["Games"]?.length > 0 ||
											currentSubscribe?.module["Organizations"]?.length > 0))
								) {
									let subFlag = item?.routes?.find((v) => {
										if (v?.path === selectedKeys[0]) {
											return v;
										}
									});
									return (
										!item?.hideInMenu && (
											<SubMenu
												selectedKeys={selectedKeys}
												key={item?.path}
												title={item?.name}
												onClick={({ key }) => {
													history.push(key);
													setHoveredParent(key); // Set hovered parent when child is clicked
												}}
												icon={
													item?.icon && (
														<img
															className="subMenu_icon"
															src={item?.icon}
															style={{ width: "22px", height: "22px" }}
															alt=""
														/>
													)
												}
											>
												{item?.routes?.map((vo, key) => {
													if (
														(currentSubscribe &&
															currentSubscribe?.module[vo?.menuName]?.length >
																0) ||
														userInfo?.type === "Admin"
													) {
														return (
															!vo?.hideInMenu && (
																<Menu.Item
																	key={vo?.path}
																	onClick={({ key }) => {
																		history.push(key);
																		setHoveredParent(key); // Set hovered parent when child is clicked
																	}}
																	icon={
																		vo?.icon && (
																			<img
																				style={{
																					width: "22px",
																					height: "22px",
																				}}
																				src={vo?.icon}
																				alt=""
																			/>
																		)
																	}
																>
																	{vo?.name}
																</Menu.Item>
															)
														);
													}
												})}
											</SubMenu>
										)
									);
								}
								if (
									userInfo?.type === "Admin" ||
									(currentSubscribe &&
										item?.menuName === "Games & Organizations" &&
										(currentSubscribe?.module["Games"]?.length > 0 ||
											currentSubscribe?.module["Organizations"]?.length > 0))
								) {
									let subFlag = item?.routes?.find((v) => {
										if (v?.path === selectedKeys[0]) {
											return v;
										}
									});
									return (
										!item?.hideInMenu && (
											<SubMenu
												selectedKeys={selectedKeys}
												key={item?.path}
												title={item?.name}
												onClick={({ key }) => {
													history.push(key);
													setHoveredParent(key); // Set hovered parent when child is clicked
												}}
												icon={
													item?.icon && (
														<img
															className="subMenu_icon"
															src={item?.icon}
															style={{ width: "22px", height: "22px" }}
															alt=""
														/>
													)
												}
											>
												{item?.routes?.map((vo, key) => {
													if (
														(currentSubscribe &&
															currentSubscribe?.module[vo?.menuName]?.length >
																0) ||
														userInfo?.type === "Admin"
													) {
														return (
															!vo?.hideInMenu && (
																<Menu.Item
																	key={vo?.path}
																	onClick={({ key }) => {
																		history.push(key);
																		setHoveredParent(key); // Set hovered parent when child is clicked
																	}}
																	icon={
																		vo?.icon && (
																			<img
																				style={{
																					width: "22px",
																					height: "22px",
																				}}
																				src={vo?.icon}
																				alt=""
																			/>
																		)
																	}
																>
																	{vo?.name}
																</Menu.Item>
															)
														);
													}
												})}
											</SubMenu>
										)
									);
								}
								if (
									userInfo?.type === "Admin" ||
									(currentSubscribe &&
										item?.menuName === "Games & Organizations" &&
										(currentSubscribe?.module["Games"]?.length > 0 ||
											currentSubscribe?.module["Organizations"]?.length > 0))
								) {
									let subFlag = item?.routes?.find((v) => {
										if (v?.path === selectedKeys[0]) {
											return v;
										}
									});
									return (
										!item?.hideInMenu && (
											<SubMenu
												selectedKeys={selectedKeys}
												key={item?.path}
												title={item?.name}
												onClick={({ key }) => {
													history.push(key);
													setHoveredParent(key); // Set hovered parent when child is clicked
												}}
												icon={
													item?.icon && (
														<img
															className="subMenu_icon"
															src={item?.icon}
															style={{ width: "22px", height: "22px" }}
															alt=""
														/>
													)
												}
											>
												{item?.routes?.map((vo, key) => {
													if (
														(currentSubscribe &&
															currentSubscribe?.module[vo?.menuName]?.length >
																0) ||
														userInfo?.type === "Admin"
													) {
														return (
															!vo?.hideInMenu && (
																<Menu.Item
																	key={vo?.path}
																	onClick={({ key }) => {
																		history.push(key);
																		setHoveredParent(key); // Set hovered parent when child is clicked
																	}}
																	icon={
																		vo?.icon && (
																			<img
																				style={{
																					width: "22px",
																					height: "22px",
																				}}
																				src={vo?.icon}
																				alt=""
																			/>
																		)
																	}
																>
																	{vo?.name}
																</Menu.Item>
															)
														);
													}
												})}
											</SubMenu>
										)
									);
								}
								if (
									userInfo?.type === "Admin" ||
									(currentSubscribe &&
										item?.menuName === "Key Companies" &&
										(currentSubscribe?.module["Company News & Data"]?.length > 0 ||
											currentSubscribe?.module["Game Regulators"]?.length > 0))
								) {
									let subFlag = item?.routes?.find((v) => {
										if (v?.path === selectedKeys[0]) {
											return v;
										}
									});
									return (
										!item?.hideInMenu && (
											<SubMenu
												selectedKeys={selectedKeys}
												key={item?.path}
												title={item?.name}
												onClick={({ key }) => {
													history.push(key);
													setHoveredParent(key); // Set hovered parent when child is clicked
												}}
												icon={
													item?.icon && (
														<img
															className="subMenu_icon"
															src={item?.icon}
															style={{ width: "22px", height: "22px" }}
															alt=""
														/>
													)
												}
											>
												{item?.routes?.map((vo, key) => {
													if (
														(currentSubscribe &&
															currentSubscribe?.module[vo?.menuName]?.length >
																0) ||
														userInfo?.type === "Admin"
													) {
														return (
															!vo?.hideInMenu && (
																<Menu.Item
																	key={vo?.path}
																	onClick={({ key }) => {
																		history.push(key);
																		setHoveredParent(key); // Set hovered parent when child is clicked
																	}}
																	icon={
																		vo?.icon && (
																			<img
																				style={{
																					width: "22px",
																					height: "22px",
																				}}
																				src={vo?.icon}
																				alt=""
																			/>
																		)
																	}
																>
																	{vo?.name}
																</Menu.Item>
															)
														);
													}
												})}
											</SubMenu>
										)
									);
								}
								if (
									userInfo?.type === "Admin" ||
									(currentSubscribe &&
										item?.menuName === "ChinaData" &&
										(currentSubscribe?.module["ISBN"]?.length > 0 ||
											currentSubscribe?.module["ChinaICafe"]?.length > 0))
								) {
									let subFlag = item?.routes?.find((v) => {
										if (v?.path === selectedKeys[0]) {
											return v;
										}
									});
									return (
										!item?.hideInMenu && (
											<SubMenu
												className={
													hoveredParent === item.path ? "hovered-parent" : ""
												}
												selectedKeys={selectedKeys}
												onClick={({ key }) => {
													history.push(key);
													setHoveredParent(key); // Set hovered parent when child is clicked
												}}
												key={item?.path}
												title={item?.name}
												icon={
													item?.icon && (
														<img
															className="subMenu_icon"
															src={item?.icon}
															style={{ width: "22px", height: "22px" }}
															alt=""
														/>
													)
												}
											>
												{item?.routes?.map((vo, key) => {
													if (
														(currentSubscribe &&
															currentSubscribe?.module[vo?.menuName]?.length >
																0) ||
														userInfo?.type === "Admin"
													) {
														return (
															!vo?.hideInMenu && (
																<Menu.Item
																	key={vo?.path}
																	onClick={({ key }) => {
																		history.push(key);
																		setHoveredParent(key); // Set hovered parent when child is clicked
																	}}
																	icon={
																		vo?.icon && (
																			<img
																				style={{
																					width: "22px",
																					height: "22px",
																				}}
																				src={vo?.icon}
																				alt=""
																			/>
																		)
																	}
																>
																	{vo?.name}
																</Menu.Item>
															)
														);
													}
												})}
											</SubMenu>
										)
									);
								}
								if (
									userInfo?.type === "Admin" ||
									(currentSubscribe &&
										item?.menuName === "News & Articles" &&
										(currentSubscribe?.module["InsightsFeed"]?.length > 0 ||
											currentSubscribe?.module["BlogPosts"]?.length > 0))
								) {
									let subFlag = item?.routes?.find((v) => {
										if (v?.path === selectedKeys[0]) {
											return v;
										}
									});
									return (
										!item?.hideInMenu && (
											<SubMenu
												className={
													hoveredParent === item.path ? "hovered-parent" : ""
												}
												selectedKeys={selectedKeys}
												onClick={({ key }) => {
													history.push(key);
													setHoveredParent(key); // Set hovered parent when child is clicked
												}}
												key={item?.path}
												title={item?.name}
												icon={
													item?.icon && (
														<img
															className="subMenu_icon"
															src={item?.icon}
															style={{ width: "22px", height: "22px" }}
															alt=""
														/>
													)
												}
											>
												{item?.routes?.map((vo, key) => {
													if (
														(currentSubscribe &&
															currentSubscribe?.module[vo?.menuName]?.length >
																0) ||
														userInfo?.type === "Admin"
													) {
														return (
															!vo?.hideInMenu && (
																<Menu.Item
																	key={vo?.path}
																	onClick={({ key }) => {
																		history.push(key);
																		setHoveredParent(key); // Set hovered parent when child is clicked
																	}}
																	icon={
																		vo?.icon && (
																			<img
																				style={{
																					width: "22px",
																					height: "22px",
																				}}
																				src={vo?.icon}
																				alt=""
																			/>
																		)
																	}
																>
																	{vo?.name}
																</Menu.Item>
															)
														);
													}
												})}
											</SubMenu>
										)
									);
								}
							} else {
								if (
									userInfo?.type === "Admin" ||
									(currentSubscribe &&
										currentSubscribe?.module[item?.menuName]?.length > 0)
								) {
									return (
										!item?.hideInMenu && (
											<Menu.Item
												onClick={({ key }) => {
													history.push(key);
													setHoveredParent(key); // Set hovered parent when child is clicked
												}}
												key={item?.path}
												icon={
													item?.icon && (
														<img
															src={item?.icon}
															alt=""
															style={{ width: "22px", height: "22px" }}
														/>
													)
												}
											>
												{item?.name}
											</Menu.Item>
										)
									);
								}
							}
						})}

						<Menu.Item
							key="custom-key" // Use key for internal identification
							onClick={() => {
								console.log("Menu Item Clicked");
							}}
						>
							<div
								onClick={(e) => {
									e.stopPropagation(); // Stops click propagation
									e.preventDefault(); // Prevent default behavior

									onNewContact();
								}}
							>
								<img
									src={userIcon}
									alt=""
									style={{
										width: "22px",
										height: "22px",
										color: "white",
										marginRight: "8px",
										marginBottom: "2px",
									}}
								/>
								<span className="contavtText">Contact an Analyst</span>
							</div>
						</Menu.Item>
					</Menu>
				</Sider>

				<Content className="main_main">
					{breadcrumb?.[0] != "home" && (
						<Breadcrumb className={breadcrumbClass}>
							{breadcrumb?.map((item, index) => {
								let active = index === breadcrumb?.length - 1;

								return (
									<Breadcrumb.Item
										key={index}
										className={`breadcrumb_item ${
											active ? "breadcrumb_item_active" : ""
										} ${
											item == "esports" && breadcrumb?.length === 2
												? "breadcrumb_item_esports"
												: ""
										}`}
									>
										{item == "game_details_paid_sample" ||
										item == "organization_detail" ||
										item == "tournament_detail" ||
										item == "details" ? (
											<span class="truncate">{modelName}</span>
										) : (
											<span
												onClick={() => nav(breadcrumbName[item])}
												style={
													pointerClass &&
													(!nav2(breadcrumbName[item])?.routes ||
														breadcrumbName[item] == "Organizations" ||
														breadcrumbName[item] == "key_companies" ||
														breadcrumbName[item] == "Company_News_Data" ||
														breadcrumbName[item] == "Games") &&
													breadcrumb[breadcrumb?.length - 1] !=
														Object.keys(breadcrumbName).find(
															(key) =>
																breadcrumbName[key] === breadcrumbName[item]
														)
														? { cursor: "pointer" }
														: { cursor: "default" }
												}
												class={"truncate2"}
											>
												{breadcrumbName[item]}
											</span>
										)}
									</Breadcrumb.Item>
								);
							})}
						</Breadcrumb>
					)}
					<Content>{children}</Content>
					<Content>
						<div className="footer-container">
							<Footer className="custom-footer">
								<div className="footer-links">
									<Link
										className="footer-link"
										onClick={() => {
											history.replace("/FAQ");
										}}
									>
										FAQ
									</Link>
									<Link
										className="footer-link"
										onClick={() => {
											window.open("https://nikopartners.com/about/");
										}}
									>
										About NikoIQ
									</Link>
									<Link
										className="footer-link"
										onClick={() => history.push("/term_and_conditions")}
									>
										Terms and Conditions
									</Link>
									<Link
										className="footer-link"
										onClick={() => history.push("/privacy_policy")}
									>
										Privacy Policy
									</Link>
								</div>
								<Divider />
								<div className="footer-lower">
									<div className="footer-content">
										<img
											src={imgSource.footer_icon}
											alt="Niko Partners Logo"
											className="footer-logo"
										/>
										<span className="footer-text">
											The Most Trusted Source of Video Game Market Research for
											Asia & MENA
											<br />
											Local Expertise with Global Perspective
										</span>
									</div>
									<div
										className="footer-copyright"
										onClick={() => {
											window.open("https://nikopartners.com/");
										}}
									>
										© Niko Partners 2024
									</div>
								</div>
							</Footer>
						</div>
					</Content>
				</Content>
			</Layout>
			<NewContact
				maskClosable={false}
				footer={null}
				visible={contactView}
				width={1200}
				setContactForm={setContactForm}
				title={"Contact an Analyst"}
				onCancel={() => {
					setContactView(false);
				}}
				onOk={(payload) => {
					effect(AContact, EPost, { ...payload }, (payload) => {
						message
							.success({
								content: `Message sent successfully`,
								className: "sc",
								duration: " 1.5",
							})
							.then(() => {
								setContactView(false);
								contactForm.resetFields();
							});
					});
				}}
			/>
		</Layout>
	);
}

export default withRouter(BasicLayout);
