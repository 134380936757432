import React from "react";
import notFound from "@/assets/err/icon_maintenance@2x.png";

const Mentain = () => (
	<div
		style={{
			display: "flex",

			alignItems: "center",
			justifyContent: "center",
			minHeight: "100vh", // Full viewport height
			textAlign: "center",
		}}
	>
		<img
			src={notFound}
			alt="404"
			style={{ maxWidth: "200px", marginBottom: "20px" }}
		/>
		<div style={{ marginLeft: "2rem" }}>
			<h1 style={{ fontWeight: 700, fontSize: "2rem", textAlign: "left" }}>
				Down for Maintenance
			</h1>
			<p style={{ maxWidth: "25rem", textAlign: "left" }}>
				This page is undergoing maintenance to bring you enhanced functionality
				and content. We apologize for any inconvenience!
			</p>
		</div>
	</div>
);

export default Mentain;
