const formatDate = (date, format = "MMM dd, yyyy") => {
	const options = {};

	// Define format options based on the provided format string
	switch (format) {
		case "yyyy":
			options.year = "numeric";
			break;
		case "mmm yyyy":
			options.month = "short";
			options.year = "numeric";
			break;
		case "mmm dd, yyyy":
			options.month = "short";
			options.day = "2-digit";
			options.year = "numeric";
			break;
		// Add more formats if needed
		default:
			options.month = "short";
			options.day = "2-digit";
			options.year = "numeric";
			break;
	}
	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export default formatDate;
