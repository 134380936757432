import { requestPost } from "../utils/dva16";
import { AContact, EPost, RSetState } from "./contants";

const AnalystContact = {
	namespace: AContact,
	state: {
		loading: false,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EPost]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestPost("analystContact", payload);
			reducer(RSetState, { loading: false });
			callback && callback();
		},
		// async [EPost]({ payload, callback }, { call, reducer, select, effect }) {
		// 	await requestPost("analystContact", payload);
		// 	callback && callback();
		// },
	},
};
export default AnalystContact;
