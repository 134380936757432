export default {
	Home: "",
	Profile: "News and Articles",
	VANAForAllTopicsDetails: "News and Articles Details",
	FreeReports: "Free Reports",
	freeReportsDetail: "FreeReport Details",
	Blog: "Blogs",
	blogDetails: "Blogs Details",
	organizations: "Organizations",
	CompanyDetail: " Company Details",
	Game: "Game",
	GameDetailsPaidSample: "Game Details",
	InsightsFeed: "Insights Feed",
	VANA: "VANA",
	NewDetails: "New Details",
	esports: "Esports",
	InsightsFeedForEsports: "Esports VANA",
	insightsFeedForChina: "Insight Feed For China",
	Tournament: "Tournaments",
	TournamentDetail: "Tournament Details",
	Teams: "Teams",
	RegulatoryOrganizations: "Regulatory Governments",
	Financial: "Financial",
	InsightsFeedForFinancial: "Insight Feed For Financial",
	CompanyRevenue: "Earnings",
	Transactions: "Transactions",
	InternetCafe: "Internet Cafe",
	IcafeGameMoreData: "ICafe Game More Data",
	Approvals: "Game Approvals",
	ChinaStreaming: "China",
	china_data: "China Data",
	isbn: "ISBN",
	internet_cafe: "Internet Cafe",
	games_organizations: "Games & Organizations",
	games: "Games",
	market_model: "Market Model",
	news_articles: "News & Articles",
	blog_posts: "Blog Posts",
	vana: "VANA Articles",
	VANA: "VANA Articles",
	user: "User",
	user_profile: "User Profile",
	nikoiq_exclusives: "Niko Knowledge Briefs",
	key_companies: "Key Companies",
	Company_News_Data: "Company News & Data",
	Game_Regulators: "Game Regulators",
	gamer_insights: "Gamer Insights",
	// nikoiq_exclusives: "Niko Knowledge Briefs",
};
