import { requestGet } from "@/utils/dva16";
import { EGet, NAnnouncement, RSetState } from "./contants";

export default {
	namespace: NAnnouncement,
	state: {
		announcementList: null,
		loadAnnouncement: false,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loadAnnouncement: true });
			let res = await requestGet("announcement", payload);
			reducer(RSetState, { announcementList: res, loadAnnouncement: false });
		},
	},
};
