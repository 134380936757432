import React, { useEffect } from "react";
import {
	Modal,
	Form,
	Input,
	Button,
	Row,
	Col,
	Typography,
	notification,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import ".././styles.less";

export default (props) => {
	const [editForm] = Form.useForm();
	const { Text } = Typography;
	const { visible, onOk, onCancel, editItem } = props;

	useEffect(() => {
		if (visible) {
		} else {
			editForm?.resetFields();
		}
	}, [visible]);

	const onOks = async () => {
		try {
			await editForm?.validateFields();
			let values = editForm?.getFieldsValue();
			delete values?.confirm;
			values.username = editItem?.username;
			const response = (await onOk) && onOk(values); // Assuming onOk returns a response object

			// Display success message from backend response (assuming it's in response.msg)
		} catch (e) {
			console.error("Validation failed:", e);
		}
	};

	return (
		<Modal {...props}>
			<Form form={editForm} className="change_Password">
				<Row>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true,
									message: "Please enter your original password!",
								},
							]}
							name="oldPassword"
							labelCol={{ span: 24 }}
							label={
								<div className="headBox">
									<Text className="label" type="secondary">
										Original Password
									</Text>
								</div>
							}
						>
							<Input.Password
								className="pro"
								placeholder="Input Password"
								iconRender={(visible) =>
									visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
								}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name="newPassword"
							labelCol={{ span: 24 }}
							label={
								<div className="headBox">
									<Text className="label" type="secondary">
										New Password
									</Text>
								</div>
							}
						>
							<Input.Password
								className="pro"
								placeholder="Input Password"
								iconRender={(visible) =>
									visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
								}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name="confirm"
							labelCol={{ span: 24 }}
							label={
								<div className="headBox">
									<Text className="label" type="secondary">
										Confirm Password
									</Text>
								</div>
							}
							rules={[
								{
									required: true,
									message: "Please confirm your password!",
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("newPassword") === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"The two passwords that you entered do not match!"
											)
										);
									},
								}),
							]}
						>
							<Input.Password
								className="pro"
								placeholder="Input Password"
								iconRender={(visible) =>
									visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
								}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Row style={{ marginTop: 28 }}>
				<Col style={{ margin: "0 auto" }}>
					<Button
						className="btn_1"
						htmlType="button"
						onClick={() => {
							onCancel && onCancel();
						}}
					>
						Cancel
					</Button>
					<Button
						className="btn_2"
						style={{ marginLeft: 25 }}
						htmlType="button"
						onClick={onOks}
					>
						OK
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};
