import React, { useState, useEffect } from "react";
import IcafeRank from "./IcafeRank";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import "moment/locale/en-au";
import "../Profile/index.less";
import CustomModal from "../../../components/modal";
import "./index.less";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import CustomEmpty from "@/utils/CustomEmpty";
import arrow from "@/assets/icon/arrow-down_mobile-table - right.png";
import sortData from "../../../utils/sorter";
import defaultLogo from "../../../assets/images/game/defaul-logo_game.png";
import { useMediaQuery } from "react-responsive";
import { customizeRenderEmpty } from "@/utils/utils";
import {
	Button,
	Row,
	Typography,
	Col,
	Table,
	Collapse,
	Form,
	Select,
	Space,
	Spin,
	DatePicker,
} from "antd";
import { effect, useStore } from "../../../utils/dva16";
import {
	NICafeGame,
	EGet,
	EGetOne,
	NGame,
	NOrganizationMulti,
	NGenre,
	EGetType,
	NLogin,
} from "../../../models/contants";
import { find } from "lodash";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Panel } = Collapse;
moment.locale("en", {
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	],
});
export default (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const { gameSearch } = useStore(NGame);
	const [filterValues, setFilterValues] = useState([]);
	const { ICafeGameList, loading } = useStore(NICafeGame);
	const { organizationSearch } = useStore(NOrganizationMulti);
	const { GenreList } = useStore(NGenre);
	const nowMonth = moment()
		.subtract(1, "month")
		.startOf("month")
		.format("YYYY-MM");
	const lastMonth = moment()
		.subtract(6, "month")
		.startOf("month")
		.format("YYYY-MM");
	const [searchValues, setSearchValues] = useState();
	const [timeColumns, setTimeColumns] = useState([]);
	console.log(timeColumns);
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint

	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};

	const [columns, setColumns] = useState([
		{
			title: "Game",
			dataIndex: "gameEn",
			key: "gameEn",
			showSorterTooltip: false,
			width: 500,
			render: (text, record, index) => {
				return (
					<>
						<div className="Tabel_Company">
							<img
								className="Tabel_Company_Logo"
								src={record?.logo ? record.logo : defaultLogo}
								alt={record?.name}
							/>
							<div className="Tabel_Company_Title">
								<p>
									<a className="a_style" href={`#/games/details/${record?.id}`}>
										{record?.gameEn}
									</a>
								</p>
								<p>
									<a className="a_style" href={`#/games/details/${record?.id}`}>
										{record?.nameCn}
									</a>
								</p>
							</div>
						</div>
					</>
				);
			},
		},
	]);
	const [actionColumns, setActionColumns] = useState([
		{
			title: "Full Details",
			key: "iCafedata",
			dataIndex: "iCafedata",
			fixed: "right",
			showSorterTooltip: false,
			width: 150,
			render: (text, record, index) => {
				return (
					<Button
						className="secReset"
						onClick={() => {
							history.push(`/china_data/internet_cafe/details/${record?.id}`);
						}}
					>
						View more{" "}
						<img
							style={{
								marginLeft: "8px",
								width: "6px",
								height: "9px",
							}}
							src={arrow}
							alt=""
						/>
					</Button>
				);
			},
		},
	]);

	const onSearchGame = (value) => {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const loadFiltersFromSession = () => {
		const savedFilters = sessionStorage.getItem("icafeFilters");
		return savedFilters ? JSON.parse(savedFilters) : null;
	};
	useEffect(() => {
		const savedFilters = loadFiltersFromSession();
		if (savedFilters) {
			setSearchValues(savedFilters);
			form.setFieldsValue({
				game: savedFilters.game,
				relatedOrganizations: savedFilters.relatedOrganizations,
				date: [
					savedFilters.startDate
						? moment(savedFilters.startDate)
						: moment(moment(nowMonth)),
					savedFilters.endDate
						? moment(savedFilters.endDate)
						: moment(lastMonth),
				],
				genre: savedFilters.genre,
			});
			effect(NICafeGame, EGet, { page: 1, perPage: 10, ...savedFilters });
		} else {
			effect(NICafeGame, EGet, { page: 1, perPage: 10 });
			effect(NGame, EGetType, { page: 1, perPage: 10 });
			effect(NOrganizationMulti, EGetType, { page: 1, perPage: 10 });
			effect(NGenre, EGet, { page: 1, perPage: 10 });
			form.setFieldsValue({
				startDate: moment(lastMonth),
				endDate: moment(nowMonth),
			});
		}
	}, []);

	useEffect(() => {
		if (searchValues && searchValues?.startDate && searchValues?.endDate) {
			let { startDate, endDate } = searchValues;
			onFormatMonth(startDate, endDate);
		} else {
			const startDate = lastMonth;
			const endDate = nowMonth;
			let game_search = form.getFieldValue().game;
			onFormatMonth_default(startDate, endDate);
		}
	}, [searchValues]);
	const onFormatMonth = (startDate, endDate) => {
		let list = [];
		let startMonth = moment(startDate)?.month();
		let a = moment(endDate)?.diff(moment(startDate), "month");
		let endMonth = moment(startDate)?.month() + a;
		for (let index = startMonth; index <= endMonth; index++) {
			let month = moment(startDate).add(index - startMonth, "months");
			list.push({
				title: moment(month)?.format("MMM YYYY"),
				key: moment(month)?.format("YYYY-MM"),
				dataIndex: moment(month)?.format("YYYY-MM"),
				align: "left",
				width: 150,
				showSorterTooltip: false,
				render: (text, record, index) => {
					let { icafe } = record;
					let monthItem = find(icafe, {
						date: moment(month)?.format("YYYY-MM"),
					});

					return (
						<span>
							{monthItem?.data
								?.toString()
								?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "0"}
						</span>
					);
				},
				sorter: (a, b) => {
					let monthA =
						find(a.icafe, {
							date: moment(month)?.format("YYYY-MM"),
						})?.data || "0";
					let monthB =
						find(b.icafe, {
							date: moment(month)?.format("YYYY-MM"),
						})?.data || "0";

					// Remove commas and convert to numbers
					let numA = parseInt(monthA.toString().replace(/,/g, ""), 10);
					let numB = parseInt(monthB.toString().replace(/,/g, ""), 10);

					return numA - numB;
				},
				showSorterTooltip: false, // Hide the default sorter tooltip
			});
		}
		setTimeColumns(list);
	};
	//月份格式化默认展示最近6个月 | Month formatting defaults to displaying the last 6 months
	const onFormatMonth_default = (startDate, endDate) => {
		let list = [];
		let startMonth = moment(startDate)?.month();
		let endMonth = moment(startDate)?.month() + 5;

		for (let index = startMonth; index <= endMonth; index++) {
			let month = moment(startDate)?.add(index - startMonth, "months");
			list?.push({
				title: moment(month)?.format("MMM YY"),
				key: moment(month)?.format("YYYY-MM"),
				render: (text, record, index) => {
					let { icafe } = record;
					let monthItem = find(icafe, {
						date: moment(month)?.format("YYYY-MM"),
					});

					return (
						<span>
							{monthItem?.data
								?.toString()
								?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "0"}
						</span>
					);
				},

				sorter: (a, b) => {
					let monthA =
						find(a.icafe, {
							date: moment(month)?.format("YYYY-MM"),
						})?.data || "0";
					let monthB =
						find(b.icafe, {
							date: moment(month)?.format("YYYY-MM"),
						})?.data || "0";

					// Remove commas and convert to numbers
					let numA = parseInt(monthA.toString().replace(/,/g, ""), 10);
					let numB = parseInt(monthB.toString().replace(/,/g, ""), 10);

					return numA - numB;
				},
			});
		}
		setTimeColumns(list);
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const onFinish = (value) => {
		value.startDate = value?.startDate
			? moment(value?.startDate?._d)?.format("YYYY-MM-DD")
			: null;
		value.endDate = value?.endDate
			? moment(value?.endDate?._d)?.format("YYYY-MM-DD")
			: null;

		setSearchValues(value);
		sessionStorage.setItem("icafeFilters", JSON.stringify(value));
		effect(NICafeGame, EGet, { page: 1, perPage: 10, ...value });
		const valuesArray = Object.values(value).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		closeModal();
		setFilterValues(valuesArray);
	};
	const onReset = () => {
		form.resetFields();
		setFormValues([]);
		setFilterValues([]);
		closeModal();
		effect(NICafeGame, EGet, { page: 1, perPage: 10 });
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetType, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const [formValues, setFormValues] = useState({});

	// Handle form value changes
	const handleValuesChange = (changedValues) => {
		setFormValues((prevValues) => ({
			...prevValues,
			...changedValues,
		}));
	};

	// Reset field function
	const resetField = (fieldName) => {
		if (fieldName === "area") {
			findRegionType([]); // Clear region based on empty area
		}
		form.setFieldsValue({ [fieldName]: [] });
		setFormValues((prevValues) => ({
			...prevValues,
			[fieldName]: [],
		}));
		let values = form.getFieldsValue();
		setFilterValues(values);
	};
	return (
		<div>
			<div>
				<p className="mainheader">Internet Cafe</p>
				<div
					className="subHeader"
					style={{ color: "#2864A1", marginBottom: "30px" }}
				>
					Monthly data is sourced from publicly available information provided
					by Shunwang. The data is updated as and when Shunwang releases it. We
					cannot guarantee data for all months.
				</div>
				<div className="subHeader">
					Filter the knowledge base for Internet Cafe data.
				</div>
			</div>
			{userInfo?.type === "Admin" ||
			(currentSubscribe?.module?.hasOwnProperty("ChinaICafe") &&
				currentSubscribe?.module["ChinaICafe"]?.length > 0) ? (
				<>
					<Spin spinning={loading} size="large">
						<div
							className="IcafeBox"
							// style={{
							// 	display: "flex",
							// 	justifyContent: "flex-end",
							// 	alignItems: "center",
							// 	padding: "0 50px",
							// 	height: "60px",
							// 	marginBottom: "-20px",
							// 	marginTop: "10px",
							// }}
						>
							<CustomModal
								isModalOpen={isModalOpen}
								showModal={showModal}
								closeModal={closeModal}
								filterValues={filterValues}
								content={
									<>
										{" "}
										<Form
											name="advanced_search"
											className="ant-advanced-search-form"
											form={form}
											onValuesChange={handleValuesChange}
											// labelCol={{ span: 8 }}
											onFinish={onFinish}
										>
											<Row>
												<Col span={24}>
													<Form.Item
														name="game"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	GAME
																</Text>
																<Button
																	disabled={
																		!formValues.game ||
																		formValues.game.length === 0
																	}
																	className={
																		!formValues.game ||
																		formValues.game.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("game")}
																	type="secondary"
																	style={{
																		marginLeft: "auto",
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
													>
														<Select
															getPopupContainer={(triggerNode) =>
																triggerNode.parentNode
															}
															showArrow
															style={{ width: "100%" }}
															showSearch
															filterOption={false}
															placeholder="Select"
															onSearch={debounce((value) => {
																onSearchGame(value);
															}, 300)}
														>
															{sortData(gameSearch?.data, "gameEn")?.map(
																(item, index) => {
																	return (
																		<Option key={index} value={item?.id}>
																			{item?.gameEn}
																		</Option>
																	);
																}
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Form.Item
														name="relatedOrganizations"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	RELATED ORGANIZATION
																</Text>
																<Button
																	disabled={
																		!formValues.relatedOrganizations ||
																		formValues.relatedOrganizations.length === 0
																	}
																	className={
																		!formValues.relatedOrganizations ||
																		formValues.relatedOrganizations.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() =>
																		resetField("relatedOrganizations")
																	}
																	type="secondary"
																	style={{
																		marginLeft: "auto",
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
													>
														<Select
															getPopupContainer={(triggerNode) =>
																triggerNode?.parentNode
															}
															showArrow
															showSearch
															filterOption={false}
															placeholder="Select"
															onSearch={debounce((value) => {
																onSearchCompany(value);
															}, 300)}
														>
															{sortData(organizationSearch?.data)?.map(
																(item, index) => {
																	return (
																		<Option key={index} value={item?.id}>
																			{item?.name}
																		</Option>
																	);
																}
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													{/* <Form.Item
														name="date"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	DATE
																</Text>
																<Button
																	disabled={
																		!formValues.date || formValues.date.length === 0
																	}
																	className={
																		!formValues.date || formValues.date.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("date")}
																	type="secondary"
																	style={{
																		marginLeft: "auto",
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
													>
														<RangePicker
															format="MMM, YYYY"
															style={{ width: "100%" }}
															getPopupContainer={(triggerNode) =>
																triggerNode.parentNode
															}
															picker="month"
															// defaultValue={[moment(nowMonth), moment(lastMonth)]}
														/>
													</Form.Item> */}

													<Form.Item
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	PUBLISHED DATE
																</Text>
																<Form.Item shouldUpdate>
																	{({ getFieldValue }) => {
																		const startDate =
																			getFieldValue("startDate");
																		const endDate = getFieldValue("endDate");
																		const isDisabled = !startDate && !endDate;
																		return (
																			<Button
																				disabled={isDisabled}
																				className={
																					isDisabled ? "resetDisabled" : "reset"
																				}
																				onClick={() => {
																					form.resetFields([
																						"startDate",
																						"endDate",
																					]);
																				}}
																				type="secondary"
																				style={{
																					marginLeft: "auto",
																					cursor: "pointer",
																					// marginBottom: "-20px",
																				}}
																			>
																				Reset
																			</Button>
																		);
																	}}
																</Form.Item>
															</div>
														}
													>
														<Row gutter={16}>
															<Col span={12}>
																<Form.Item name="startDate" noStyle>
																	<DatePicker
																		format="MMM, YYYY"
																		getPopupContainer={(triggerNode) =>
																			triggerNode.parentNode
																		}
																		style={{ width: "100%" }}
																		placeholder="Start Date"
																	/>
																</Form.Item>
															</Col>
															<Col span={12}>
																<Form.Item name="endDate" noStyle>
																	<DatePicker
																		format="MMM, YYYY"
																		getPopupContainer={(triggerNode) =>
																			triggerNode.parentNode
																		}
																		style={{ width: "100%" }}
																		placeholder="End Date"
																	/>
																</Form.Item>
															</Col>
														</Row>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Form.Item
														name="genre"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	GENRE
																</Text>
																<Button
																	disabled={
																		!formValues.genre ||
																		formValues.genre.length === 0
																	}
																	className={
																		!formValues.genre ||
																		formValues.genre.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("genre")}
																	type="secondary"
																	style={{
																		marginLeft: "auto",
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
													>
														<Select
															// style={{ width: "80%" }}

															getPopupContainer={(triggerNode) =>
																triggerNode?.parentNode
															}
															showArrow
															showSearch
															filterOption={false}
															placeholder="Select"
															onSearch={debounce((value) => {
																onSearchGenre(value);
															}, 300)}
														>
															{sortData(GenreList?.data, "genre")?.map(
																(item, index) => {
																	return (
																		<Option key={index} value={item?.id}>
																			{item?.lookupValue}
																		</Option>
																	);
																}
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24} style={{ textAlign: "right" }}>
													<Space>
														<Button
															type="primary"
															htmlType="submit"
															className="applyBtn"
														>
															Apply Now
														</Button>
														<Button
															type="default"
															onClick={onReset}
															className="resetBtn"
														>
															Reset All
														</Button>
													</Space>
												</Col>
											</Row>
										</Form>
									</>
								}
							/>
						</div>
						<div className="CompanyBox">
							<Row className="RowBox mt30">
								<Col span={24}>
									{!isMobile ? (
										<Table
											showSorterTooltip={false}
											locale={{ emptyText: CustomEmpty }}
											scroll={{ x: "max-content" }}
											className="CompanyTableBox"
											pagination={false}
											columns={columns?.concat(timeColumns, actionColumns)}
											dataSource={ICafeGameList?.data}
											onChange={(pagination, filters, sort) => {
												const pageSize = ICafeGameList?.perPage || 10; // Get the pageSize from your data or default
												const currentPage = ICafeGameList?.page || 1; // Get the current page from your data or default

												effect(NICafeGame, EGet, {
													perPage: pageSize,
													page: currentPage,
													...searchValues,
												});
											}}
										/>
									) : (
										<Collapse
											accordion
											activeKey={activeKey}
											onChange={handleCollapseChange}
											className="MobileList"
											expandIconPosition="right"
											expandIcon={({ isActive }) => (
												<div className="custom-arrow">
													<img
														src={isActive ? reverseArrow : iconArrow}
														alt="arrow"
													/>
												</div>
											)}
										>
											{ICafeGameList?.data?.map((item) => (
												<Panel
													header={
														<div className="panel-header">
															<div>
																{item?.logo && (
																	<img
																		src={item.logo} // Assuming item has an imageSrc property
																		alt="tournament"
																		className="tournament-image"
																	/>
																)}
																<span>
																	<a
																		className="a_style"
																		href={`#/games/details/${item?.id}`}
																	>
																		{item?.gameEn}
																	</a>

																	<a
																		className="a_style"
																		href={`#/games/details/${item?.id}`}
																	>
																		{item?.nameCn}
																	</a>
																</span>
															</div>
														</div>
													}
													key={item.id}
												>
													<p>
														{timeColumns?.map((time) => {
															const matchingRecord = item?.icafe?.find(
																(el) => el.date === time?.key
															);
															const formattedData = matchingRecord
																? new Intl.NumberFormat().format(
																		matchingRecord.data
																  )
																: "-";
															return (
																<Row key={time.date}>
																	<Col className="head" span={12}>
																		{time?.title}
																	</Col>
																	<Col className="text" span={12}>
																		{formattedData ? formattedData : "-"}
																	</Col>
																</Row>
															);
														})}
														{
															<Button
																className="secReset"
																onClick={() => {
																	history.push(
																		`/china_data/internet_cafe/details/${item?.id}`
																	);
																}}
															>
																View more{" "}
																<img
																	style={{
																		marginLeft: "8px",
																		width: "6px",
																		height: "9px",
																	}}
																	src={arrow}
																	alt=""
																/>
															</Button>
														}
													</p>
												</Panel>
											))}
										</Collapse>
									)}
								</Col>
							</Row>
						</div>
						{/* <div className="CompanyBox">
							<div className=" BlogBox mt20 wd100 ">
								<div className="RowBox BorderBox" style={{ marginBottom: 100 }}>
									<div className="Global_title Global_Jb_title BoxPadding">
										<h2 style={{ color: "#999999", padding: "15px", margin: 0 }}>
											Rank Change
										</h2>
									</div>
									<div className="Insights_List">
										<IcafeRank />
									</div>
								</div>
							</div>
						</div> */}
					</Spin>
				</>
			) : (
				customizeRenderEmpty()
			)}
		</div>
	);
};
