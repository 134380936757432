import {
	requestGet,
	requestPost,
	requestPut,
	requestDelete,
} from "../utils/dva16";

import {
	NCompany,
	EGetTop,
	EGet,
	EGetOne,
	EGetDetail,
	EPost,
	EPut,
	RSetState,
	EDelete,
	EGetType,
} from "./contants";

const Company = {
	namespace: NCompany,
	state: {
		loading: false,
		companyStream: null,
		companyChartData: null,
		companyData: null,
		companyDetailData: null,
		companySearch: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("company", payload);
			reducer(RSetState, { companyData: res, loading: false });
		},
		async [EGetType]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("companySearch", payload);
			reducer(RSetState, { companySearch: res });
		},
		async [EGetTop]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("companyStream", payload);
			reducer(RSetState, { companyStream: res });
		},
		async [EGetDetail](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet(`company/${payload?.id}`, payload);
			reducer(RSetState, { companyDetailData: res, loading: false });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("getCompanyTitle", payload);
			reducer(RSetState, { companyChartData: res });
		},

		async [EPost]({ payload, callback }, { call, reducer, select, effect }) {
			await requestPost("reports", payload);
			callback && callback();
		},
		async [EPut]({ payload, callback }, { call, reducer, select, effect }) {
			await requestPut(`reports/${payload?.id}`, payload);
			callback && callback();
		},
		async [EDelete]({ payload, callback }, { call, reducer, select, effect }) {
			await requestDelete(`reports/${payload?.id}`, payload);
			callback && callback();
		},
	},
};
export default Company;
