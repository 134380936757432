import { requestGet } from "../utils/dva16";
import { MName, EGet, RSetState } from "./contants";

const ModelName = {
	namespace: MName,
	state: {
		modelName: "",
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { modelName: payload?.modelName });
		},
	},
};
export default ModelName;
