import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import routes from "./routes";
import BasicLayout from "./layout/BasicLayout";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ForgetPassword from "./pages/ResetPassword/ForgetPassword";
import FAQpage from "./pages/FAQ/FAQpage";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy";
import TermsOfUse from "./pages/Terms/TermsOfUse";
import NotFound from "./layout/components/NotFound.jsx";
import ScrollToTop from "./utils/scrollTop.jsx";
function App(props) {
	return (
		<HashRouter>
			<Switch>
				{/* Redirect root to home */}
				<Route exact path={"/"} render={() => <Redirect to="/home" />} />

				{/* Public routes */}
				<Route exact path={"/Login"} component={Login} />
				<Route exact path={"/ResetPassword"} component={ResetPassword} />
				<Route exact path={"/FAQ"} component={FAQpage} />
				<Route exact path={"/ForgetPassword"} component={ForgetPassword} />
				<Route exact path={"/privacy_policy"} component={PrivacyPolicy} />
				<Route exact path={"/term_and_conditions"} component={TermsOfUse} />

				{/* Routes wrapped in BasicLayout */}
				<Route
					path="/"
					render={() => (
						<BasicLayout>
							<ScrollToTop />
							<Switch>
								{routes?.map((item, key) =>
									item?.routes ? (
										item?.routes?.map((vo, index) => (
											<Route
												key={index}
												exact={vo?.exact}
												path={vo?.path}
												component={vo?.component}
											/>
										))
									) : (
										<Route
											key={key}
											exact={item?.exact}
											path={item?.path}
											component={item?.component}
										/>
									)
								)}

								{/* NotFound for undefined paths within BasicLayout */}
								<Route component={NotFound} />
							</Switch>
						</BasicLayout>
					)}
				/>

				{/* Catch-all route for undefined paths outside of BasicLayout */}
				<Route component={NotFound} />
			</Switch>
		</HashRouter>
	);
}

export default App;
