import React, { useEffect, useState } from "react";
import { Tabs, Empty } from "antd";
import { NTab, EGet, NLogin } from "../../../models/contants";
import { effect, useStore } from "../../../utils/dva16";
import ICafeGame from "./ICafeGame";
import Mentain from "../../../utils/mentain.jsx";
import ICafeTable from "./ICafeTable";
import { customizeRenderEmpty } from "@/utils/utils";
const { TabPane } = Tabs;

const ICafeTabs = () => {
	const { tabList } = useStore(NTab);
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const [activeTab, setActiveTab] = useState("2");

	useEffect(() => {
		effect(NTab, EGet, { page_name: "icafe" });
	}, []);

	const onChange = (key) => {
		setActiveTab(key);
	};

	const customizeRenderEmptyM = () => <Mentain />;

	return (
		<>
			{userInfo?.type === "Admin" ||
			(currentSubscribe?.module?.hasOwnProperty("ChinaICafe") &&
				currentSubscribe?.module["ChinaICafe"]?.length > 0) ? (
				<>
					<Tabs
						onChange={onChange}
						activeKey={activeTab}
						type="card"
						className="custom-tabs"
					>
						{tabList?.[0]?.bi_tab == 1 && (
							<TabPane tab="Dashboard" key="1">
								<ICafeGame />
							</TabPane>
						)}
						{tabList?.[0]?.table_tab == 1 && (
							<TabPane tab="Table" key="2">
								<ICafeTable />
							</TabPane>
						)}
					</Tabs>
					{tabList?.[0]?.bi_tab != 1 && tabList?.[0]?.table_tab != 1
						? customizeRenderEmptyM()
						: ""}
				</>
			) : (
				customizeRenderEmpty()
			)}
		</>
	);
};

export default ICafeTabs;
