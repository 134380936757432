import { requestGet } from "../utils/dva16";
import { NExchange, EGet, RSetState } from "./contants";
const Exchange = {
	namespace: NExchange,
	state: {
		ExchangeList: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("tickerSymbol", payload);
			reducer(RSetState, { ExchangeList: res?.data });
		},
	},
};
export default Exchange;
