import { UPLOAD_SERVER_HOME } from '@/config/Config';
import { requestFile } from '@/utils/dva16';
import { i18nChangeLanguage } from '@wangeditor/editor';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import '@wangeditor/editor/dist/css/style.css';
import { message } from 'antd';
import { useEffect, useState } from 'react';
i18nChangeLanguage('en');
export default (props) => {
	const { value, onChange, customStyle } = props;
	const [editor, setEditor] = useState(null);
	// 工具栏配置 | Tool configuration
	const toolbarConfig = {
		excludeKeys: ['group-video', 'insertImage'],
	};
	// 编辑器配置 | Editor Configuration
	const editorConfig = {
		placeholder: 'Input here',
		MENU_CONF: {
			uploadImage: {
				async customUpload(file, insertFn) {
					message.loading('图片上传中..', 0);
					const { url } = await requestFile(UPLOAD_SERVER_HOME, file);
					insertFn(url, url, url);
					message.destroy();
				},
			},
		},
	};
	useEffect(() => {
		return () => {
			if (editor == null) return;
			editor.destroy();
			setEditor(null);
		};
	}, [editor]);
	console.log(customStyle);
	return (
		<div style={{ border: '1px solid #ccc', zIndex: 100 }}>
			<Toolbar
				editor={editor}
				defaultConfig={toolbarConfig}
				mode="default"
				style={{ borderBottom: '1px solid #ccc' }}
			/>
			<Editor
				 className="editor-hover"
				defaultConfig={editorConfig}
				value={value}
				onCreated={setEditor}
				onChange={(currentEditor) => onChange(currentEditor.getHtml())}
				mode="default"
				style={customStyle ? customStyle : { height: '500px', overflowY: 'hidden' }}
			/>
		</div>
	);
};
