import React, { useState } from "react";
import "../Login/index.less";
import { Button, Typography, Form, Input } from "antd";
import { effect } from "../../utils/dva16";
import { NLogin, EPostCompletePassWordReset } from "../../models/contants";
const { Link } = Typography;
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import * as qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

export default (props) => {
	const [editForm] = Form.useForm();
	const history = useHistory();
	const location = useLocation();
	const parsed = qs.parse(location.search);
	const [resetDone, setResetDone] = useState(false);

	const onOks = async () => {
		try {
			await editForm.validateFields();
			let values = editForm.getFieldValue();
			const token = parsed?.token;
			const { newPassword } = values;
			const payload = { token, newPassword };
			effect(NLogin, EPostCompletePassWordReset, payload, () => {
				console.log("resetDone 1", resetDone);

				setResetDone(true);
				console.log("resetDone 2", resetDone);
			});
		} catch (e) {}
	};

	const validatePassword = (_, value) => {
		// Password must be 8-120 characters and include at least three out of four complexity rules
		const passwordRegex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,120}$/;

		if (!passwordRegex.test(value)) {
			return Promise.reject(
				"Password must be 8-120 characters and contains at least 1 uppercase, 1 lowercase, 1 numeric, and 1 special character."
			);
		}

		return Promise.resolve();
	};

	return (
		<div>
			<div className="LoginBox">
				<header>
					<div className="LogoBox">
						<Link herf="">
							<img src={require("@/assets/icon/logo_niko.png")?.default} />
						</Link>
					</div>
				</header>
				{resetDone ? (
					<div className="LoginFormBox" style={{ textAlign: "center" }}>
						<div className="LoginTitleBox">
							<h2>Your Password Has Been Reset</h2>
							<h3 style={{ margin: "5rem 0" }}>
								Your password has been updated successfully. You can <br />
								now use your new password to log in.
							</h3>
						</div>
					</div>
				) : (
					<div className="LoginFormBox">
						<div className="LoginTitleBox">
							<h2>Reset your password</h2>
							<h3 style={{ marginBottom: "5rem" }}>
								Type in your new password
							</h3>
						</div>
						<Form form={editForm} autoComplete="off">
							<Form.Item
								name="newPassword"
								rules={[
									{
										required: true,
										message: "Please input your password!",
									},
									{
										validator: validatePassword,
									},
								]}
							>
								<Input.Password
									style={{ width: "100%" }}
									placeholder="New password *"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
								/>
							</Form.Item>
							<Form.Item
								style={{ marginBottom: "5rem" }}
								name="confirm"
								rules={[
									{
										required: true,
										message: "Please confirm your password!",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("newPassword") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													"The two passwords that you entered do not match!"
												)
											);
										},
									}),
								]}
							>
								<Input.Password
									style={{ width: "100%" }}
									placeholder="Retry new password *"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
								/>
							</Form.Item>
							<Form.Item>
								<Button
									htmlType="submit"
									onClick={() => {
										onOks();
									}}
									type="primary"
									size="large"
									// className="Login_Btn_Box"
									className="Login_Btn_Box_Reset_small"
								>
									RESET
								</Button>
							</Form.Item>
						</Form>
					</div>
				)}
				<div className="footer">
					<Link
						className="linkGoLogin linkItem"
						onClick={() => history.replace("/login")}
					>
						Back to login
					</Link>
					{/* <Link
						className="linkItem"
						onClick={() => history.push("/term_and_conditions")}
					>
						Terms and conditions
					</Link>
					<span style={{ margin: "0 1rem" }}> - </span>
					<Link
						className="linkItem"
						onClick={() => history.push("/privacy_policy")}
					>
						Privacy policy
					</Link> */}
				</div>
			</div>
		</div>
	);
};
