import { NGlobal, RSetState } from './contants';

const Global = {
  namespace: NGlobal,
  state: {
    collapsed: false,
  },
  reducers: {
    [RSetState](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
export default Global;
