import { requestGet } from "../utils/dva16";

import {
	NMAIPO,
	EGet,
	EGetCollect,
	EGetOne,
	EGetTop,
	RSetState,
} from "./contants";

const MAIPO = {
	namespace: NMAIPO,
	state: {
		MAIPOList: null,
		MAIPOTitle: null,
		loading: false,
		ipoTopList: null,
		GameDetail: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("ipo", payload);
			reducer(RSetState, { MAIPOList: res, loading: false });
		},
		async [EGetCollect](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("ipoTitle", payload);
			reducer(RSetState, { MAIPOTitle: res });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet(`v2/game/${payload?.id}`, payload);
			reducer(RSetState, { GameDetail: res, loading: false });
		},
		async [EGetTop]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("ipoTop", payload);
			reducer(RSetState, { ipoTopList: res, loading: false });
		},
	},
};
export default MAIPO;
