import RichTextInput from "@/components/RichTextInput";

import { useStore } from "@/utils/dva16";
import { NLogin } from "../../models/contants";
import {
	Button,
	Card,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Spin,
	Typography,
} from "antd";
import "braft-editor/dist/index.css";
import { debounce } from "lodash";

import { useEffect, useState } from "react";

export default (props) => {
	const { data, onOk, visible, onCancel, setContactForm } = props;

	const [form] = Form.useForm();

	const { userInfo } = useStore(NLogin);
	const [errorMessage, setErrorMessage] = useState("");
	const handleSubmit = () => {
		setErrorMessage("Input something!");
		form.getFieldValue("message") == "<p><br></p>"
			? setTextAreaERR(true)
			: setTextAreaERR(false);
	};
	useEffect(() => {
		setContactForm && setContactForm(form);
		if (visible) {
			form.setFieldsValue({
				message: (data && data?.message) || "",
				id: (data && data?.id) || "",
			});
			console.log(data);
		}

		return () => {};
	}, [visible]);

	const onFinish = debounce((values) => {
		values.email = userInfo.email;
		values.company = userInfo?.company;
		values.name = userInfo?.username;
		values.message != "<p><br></p>" && onOk && onOk(values);
	}, 500);
	const [textAreaERR, setTextAreaERR] = useState(false);
	const customEditorStyle = {
		height: "500px",

		border: "1px solid red",
	};

	return (
		<Modal {...props}>
			{/* <Spin  size="large" delay={300}> */}
			<Card bordered={false}>
				<Form
					onFinish={onFinish}
					form={form}
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }} // Ensure wrapperCol spans full width
					autoComplete="off"
					style={{
						maxWidth: "1200px", // Set a maximum width for the form
						margin: "0 auto", // Center the form horizontally
					}}
				>
					<Form.Item
						name="title"
						label="TITLE"
						rules={[
							{
								required: true,
								message: "Input something!",
							},
						]}
					>
						<Input placeholder="Input here" />
					</Form.Item>
					<Form.Item
						rules={[{ required: true, message: "Input something!" }]}
						label="Message"
						name="message"
						help={
							textAreaERR ? (
								<span style={{ color: "red" }}>{errorMessage}</span>
							) : (
								""
							)
						}
					>
						<RichTextInput
							customStyle={textAreaERR ? customEditorStyle : null}
						/>
					</Form.Item>
					<Row>
						<Col style={{ margin: "0 auto" }}>
							<Button
								className="primarySubmit"
								size="large"
								type="primary"
								htmlType="submit"
								style={{ margin: "0 24px" }}
								onClick={() => {
									handleSubmit();
								}}
							>
								Submit
							</Button>
							<Button
								className="secCancel"
								size="large"
								onClick={() => {
									onCancel && onCancel();
								}}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Form>
			</Card>
			{/* </Spin> */}
		</Modal>
	);
};
