import React from "react";
import { Layout, Typography } from "antd";
import "./index.less";
import { useMediaQuery } from "react-responsive";
import headerImg from "../../assets/header.png";
const { Content } = Layout;
const { Title, Paragraph, Link } = Typography;

const PrivacyPolicy = (props) => {
	const isSmallScreen = useMediaQuery({ maxWidth: 768 });
	const { history } = props;

	return (
		<Layout className="container">
			<header>
				<div className="LogoBox">
					<header className="header">
						<div onClick={() => history.push("/")}>
							<img src={headerImg} className="headerimg" />
						</div>
					</header>
				</div>
			</header>
			<Content className="content">
				<Typography>
					<Title
						level={2}
						className="pageTitle"
						style={{ fontSize: isSmallScreen ? "2rem" : "3rem" }}
					>
						Privacy Policy
					</Title>
					<Paragraph strong className="subTitle">
						January 1, 2024
					</Paragraph>
					<Paragraph strong className="subTitle">
						Niko Partners, Inc.
					</Paragraph>

					<Paragraph>
						This Privacy Policy explains how Niko Inc. (“Niko,” “we,” or “us”)
						collects, uses, and discloses information about you. This Privacy
						Policy applies when you use our websites and online products and
						services that link to this Privacy Policy (collectively, our
						“Services”), contact our customer service team, engage with us on
						social media, or otherwise interact with us.
					</Paragraph>

					<Paragraph>
						We may change this Privacy Policy from time to time. If we make
						changes, we will notify you by revising the date at the top of this
						policy and, in some cases, we may provide you with additional notice
						(such as adding a statement to our website or providing you with a
						notification). We encourage you to review this Privacy Policy
						regularly to stay informed about our information practices and the
						choices available to you.
					</Paragraph>

					<Title level={3} className="subTitle">
						Contents
					</Title>
					<Paragraph>Collection of Information</Paragraph>
					<Paragraph>Use of Information</Paragraph>
					<Paragraph>Sharing of Information</Paragraph>
					<Paragraph>
						Transfer of Information to the United States and Other Countries
					</Paragraph>
					<Paragraph>Your Choices</Paragraph>
					<Paragraph>Your California Privacy Rights</Paragraph>
					<Paragraph>
						Additional Disclosures for Individuals in Europe
					</Paragraph>
					<Paragraph>Contact Us</Paragraph>

					<Title level={3} className="subTitle">
						Collection of Information
					</Title>

					<Paragraph>
						<strong>Information You Provide to Us</strong>
						<br />
						We collect information you provide directly to us. For example, you
						share information directly with us when you create an account, fill
						out a form, purchase a membership, communicate with us via
						third-party platforms, request customer support, or otherwise
						communicate with us. The types of personal information we may
						collect include your name, display name, username, email address,
						business information and any other information you choose to
						provide.
					</Paragraph>

					<Paragraph>
						We do not collect payment information through our Services. We rely
						on third parties to process payments in connection with our
						Services. Any information you provide to facilitate such a payment
						is subject to the third-party payment processor’s privacy policy,
						and we encourage you to review this policy before you provide any
						information to the payment processor.
					</Paragraph>

					<Paragraph>
						<strong>
							Information We Collect Automatically When You Interact with Us
						</strong>
						<br />
						In some instances, we automatically collect certain information,
						including:
						<ul>
							<li>
								Activity Information: We collect information about your activity
								on our Services.
							</li>
							<li>
								Device and Usage Information: We collect information about how
								you access our Services, including data about the device and
								network you use, such as your hardware model, operating system
								version, mobile network, IP address, unique device identifiers,
								browser type, and app version. We also collect information about
								your activity on our Services, such as access times, pages
								viewed, links clicked, and the page you visited before
								navigating to our Services.
							</li>
						</ul>
					</Paragraph>

					<Paragraph>
						<strong>
							Information Collected by Cookies and Similar Tracking Technologies
						</strong>
						<br />
						We use tracking technologies, such as cookies, to collect
						information about you. Cookies are small data files stored on your
						hard drive or in device memory that help us improve our Services and
						your experience, see which areas and features of our Services are
						popular, and count visits. This information may be used by Niko and
						others to, among other things, analyze and track data, determine the
						popularity of certain content, deliver content targeted to your
						interests on our Services, and better understand your online
						activity.
					</Paragraph>

					<Paragraph>
						<strong>Information We Collect from Other Sources</strong>
						<br />
						We obtain information from third-party sources. For example, we may
						collect information about you from social networks, accounting
						services providers and data analytics providers.
					</Paragraph>

					<Paragraph>
						<strong>Information We Derive</strong>
						<br />
						We may derive information or draw inferences about you based on the
						information we collect. For example, we may make inferences about
						your location based on your IP address or infer reading preferences
						based on your reading history.
					</Paragraph>

					<Title level={3} className="subTitle">
						Use of Information
					</Title>

					<Paragraph>
						We use the information we collect to provide, maintain, and improve
						our Services, which includes publishing and distributing information
						and content. We also use the information we collect to:
						<ul>
							<li>Create and maintain your Niko account</li>
							<li>Process user experience surveys</li>
							<li>
								Send you technical notices, security alerts, and support and
								administrative messages
							</li>
							<li>Provide customer service</li>
							<li>
								Communicate with you about new content, products, services, and
								features offered by Niko and provide other news and information
								we think will interest you
							</li>
							<li>
								Monitor and analyze trends, usage, and activities in connection
								with our Services
							</li>
							<li>
								Detect, investigate, and prevent security incidents and other
								malicious, deceptive, fraudulent, or illegal activity and
								protect the rights and property of Niko and others
							</li>
							<li>Debug to identify and repair errors in our Services</li>
							<li>Comply with our legal and financial obligations</li>
						</ul>
					</Paragraph>

					<Title level={3} className="subTitle">
						Sharing of Information
					</Title>

					<Paragraph>
						We share personal information in the following circumstances or as
						otherwise described in this policy:
						<ul>
							<li>
								We share personal information with vendors, service providers,
								and consultants that need access to personal information in
								order to perform services for us, such as companies that assist
								us with web hosting, storage, and other infrastructure,
								analytics, payment processing, fraud prevention and security,
								customer service, communications, and marketing.
							</li>
							<li>
								We may disclose personal information if we believe that
								disclosure is in accordance with, or required by, any applicable
								law or legal process.
							</li>
							<li>
								We may share personal information if we believe that your
								actions are inconsistent with our website, if we believe that
								you have violated the law, or if we believe it is necessary to
								protect the rights, property, and safety of Niko, our users, the
								public, or others.
							</li>
							<li>
								We share personal information with our lawyers and other
								professional advisors where necessary to obtain advice or
								otherwise protect and manage our business interests.
							</li>
							<li>
								We may share personal information in connection with, or during
								negotiations concerning, any merger, sale of company assets,
								financing, or acquisition of all or a portion of our business by
								another company.
							</li>
							<li>
								We share personal information with your consent or at your
								direction.
							</li>
							<li>
								We also share aggregated or de-identified information that
								cannot reasonably be used to identify you.
							</li>
						</ul>
					</Paragraph>

					<Title level={3} className="subTitle">
						Transfer of Information to the United States and Other Countries
					</Title>

					<Paragraph>
						Niko is headquartered in the United States, and we have operations
						and service providers in the United States and other countries.
						Therefore, we and our service providers may transfer your personal
						information to, or store or access it in, jurisdictions that may not
						provide levels of data protection that are equivalent to those of
						your home jurisdiction. For example, we transfer personal data to
						Alibaba and Google, our service providers that process personal
						information for us in various data center locations across the
						globe. We will take steps to ensure that your personal information
						receives an adequate level of protection in the jurisdictions in
						which we process it.
					</Paragraph>

					<Title level={3} className="subTitle">
						Your Choices
					</Title>

					<Paragraph>
						<strong>Account Information</strong>
						<br />
						You may access, correct and your account information at any time by
						going to the User Center or contacting support@niko.com. Please note
						that if you choose to delete your account, we may continue to retain
						certain information about you as required by law or for our
						legitimate business purposes.
					</Paragraph>

					<Paragraph>
						<strong>Cookies</strong>
						<br />
						Most web browsers are set to accept cookies by default. If you
						prefer, you can usually adjust your browser settings to remove or
						reject browser cookies. Please note that removing or rejecting
						cookies could affect the availability and functionality of our
						Services.
					</Paragraph>

					<Paragraph>
						<strong>Communications Preferences</strong>
						<br />
						You may opt out of receiving certain communications from us, such as
						digests, newsletters, and activity notifications, by following the
						instructions in those communications or through your account’s user
						Center. If you opt out, we may still send you administrative emails,
						such as those about your account or our ongoing business relations.
					</Paragraph>

					<Title level={3} className="subTitle">
						Your California Privacy Rights
					</Title>

					<Paragraph>
						The California Consumer Privacy Act or “CCPA” (Cal. Civ. Code §
						1798.100 et seq.) affords consumers residing in California certain
						rights with respect to their personal information. If you are a
						California resident, this section applies to you.
					</Paragraph>

					<Paragraph>
						In the preceding 12 months, we have collected the following
						categories of personal information: identifiers, commercial
						information, internet or other electronic network activity
						information, and inferences. We collect personal information for the
						business and commercial purposes described in the Use of Information
						section above.
					</Paragraph>

					<Paragraph>Niko does not sell your personal information.</Paragraph>

					<Paragraph>
						Subject to certain limitations, you have the right to (1) request to
						know more about the categories and specific pieces of personal
						information we collect, use, and disclose about you, (2) request
						deletion of your personal information, (3) opt out of any sales of
						your personal information, if we engage in that activity in the
						future, and (4) not be discriminated against for exercising these
						rights. You may make these requests by emailing us at
						support@nikopartners.com. We will not discriminate against you if
						you exercise your rights under the CCPA.
					</Paragraph>

					<Title level={3} className="subTitle">
						Additional Disclosures for Individuals in Europe
					</Title>

					<Paragraph>
						If you are located in the European Economic Area (“EEA”), the United
						Kingdom, or Switzerland, you have certain rights and protections
						under applicable law regarding the processing of your personal data,
						and this section applies to you.
					</Paragraph>

					<Paragraph>
						<strong>Legal Basis for Processing</strong>
						<br />
						When we process your personal data, we will do so in reliance on the
						following lawful bases:
						<ul>
							<li>
								To perform our responsibilities under our contract with you
								(e.g., providing the products and services you requested).
							</li>
							<li>
								When we have a legitimate interest in processing your personal
								data to operate our business or protect our interests (e.g., to
								provide, maintain, and improve our products and services,
								conduct data analytics, and communicate with you).
							</li>
							<li>
								To comply with our legal obligations (e.g., to maintain a record
								of your consents and track those who have opted out of
								non-administrative communications).
							</li>
							<li>
								When we have your consent to do so (e.g., when you opt in to
								receive non-administrative communications from us). When consent
								is the legal basis for our processing your personal data, you
								may withdraw such consent at any time.
							</li>
						</ul>
					</Paragraph>

					<Paragraph>
						<strong>Data Retention</strong>
						<br />
						We store personal data associated with your account for as long as
						your account remains active. If you close your account, we will
						delete your account data within 14 days. We store other personal
						data for as long as necessary to carry out the purposes for which we
						originally collected it and for other legitimate business purposes,
						including to meet our legal, regulatory, or other compliance
						obligations.
					</Paragraph>

					<Paragraph>
						<strong>Data Subject Requests</strong>
						<br />
						Subject to certain limitations, you have the right to request access
						to the personal data we hold about you and to receive your data in a
						portable format, the right to ask that your personal data be
						corrected or erased, and the right to object to, or request that we
						restrict, certain processing. Please contact
						support@nikopartners.com with your request.
					</Paragraph>

					<Paragraph>
						<strong>Questions or Complaints</strong>
						<br />
						If you have a concern about our processing of personal data that we
						are not able to resolve, you have the right to lodge a complaint
						with the Data Protection Authority where you reside. Contact details
						for your Data Protection Authority can be found using the links
						below:
						<ul>
							<li>
								For individuals in the EEA:{" "}
								<a
									href="https://edpb.europa.eu/about-edpb/board/members_en"
									target="_blank"
									rel="noopener noreferrer"
								>
									EDPB Members
								</a>
							</li>
							<li>
								For individuals in the UK:{" "}
								<a
									href="https://ico.org.uk/global/contact-us/"
									target="_blank"
									rel="noopener noreferrer"
								>
									ICO Contact Us
								</a>
							</li>
							<li>
								For individuals in Switzerland:{" "}
								<a
									href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html"
									target="_blank"
									rel="noopener noreferrer"
								>
									FDPIC Contact
								</a>
							</li>
						</ul>
					</Paragraph>

					<Title level={3} className="subTitle">
						Contact Us
					</Title>

					<Paragraph>
						If you have any questions about this Privacy Policy, please contact
						us at support@nikopartners.com.
					</Paragraph>
				</Typography>
			</Content>
		</Layout>
	);
};

export default PrivacyPolicy;
