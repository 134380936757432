import { requestGet } from "../utils/dva16";
import { NPlatform, EGet, EGetType, RSetState } from "./contants";

const Platform = {
	namespace: NPlatform,
	state: {
		PlatformData: null,
		loading: false,
		TypeList: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("platform", payload);
			reducer(RSetState, { PlatformData: res, loading: false });
		},
		async [EGetType]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("typeSelect", payload);
			reducer(RSetState, { TypeList: res, loading: false });
		},
	},
};
export default Platform;
