import React from "react";
import notFound from "@/assets/err/icon_404@2x.png";

const NotFound = () => (
	<div
		style={{
			display: "flex",

			alignItems: "center",
			justifyContent: "center",
			minHeight: "100vh", // Full viewport height
			textAlign: "center",
		}}
	>
		<img
			src={notFound}
			alt="404"
			style={{ maxWidth: "200px", marginBottom: "20px" }}
		/>
		<div style={{ marginLeft: "2rem" }}>
			<h1 style={{ fontWeight: 700, fontSize: "2rem", textAlign: "left" }}>
				404 - Page Not Found
			</h1>
			<p style={{ maxWidth: "25rem", textAlign: "left" }}>
				Don’t worry, we’re here to help. Please try searching for what you need
				or contact our support team at{" "}
				<a href="mailto:support@nikopartners.com" className="linkItem">
					support@nikopartners.com
				</a>
				.
			</p>
		</div>
	</div>
);

export default NotFound;
