const sortData = (data, model) => {
	if (!data) return [];
	return data?.sort((a, b) => {
		let itemA, itemB;
		switch (model) {
			case "username":
				itemA = (a.username || "").trim().toLowerCase();
				itemB = (b.username || "").trim().toLowerCase();
				break;
			case "clientName":
				itemA = (a.clientName || "").trim().toLowerCase();
				itemB = (b.clientName || "").trim().toLowerCase();
				break;
			case "area":
				itemA = (a.otherData || "").trim().toLowerCase();
				itemB = (b.otherData || "").trim().toLowerCase();
				break;
			case "text":
				itemA = (a.text || "").trim().toLowerCase();
				itemB = (b.text || "").trim().toLowerCase();
				break;
			case "name":
				itemA = (a.name || "").trim().toLowerCase();
				itemB = (b.name || "").trim().toLowerCase();
				break;
			case "nameEN":
				itemA = (a.nameEN || "").trim().toLowerCase();
				itemB = (b.nameEN || "").trim().toLowerCase();
				break;
			case "gameEn":
				itemA = (a.gameEn || "").trim().toLowerCase();
				itemB = (b.gameEn || "").trim().toLowerCase();
				break;
			case "genre":
				itemA = (a.lookupValue || "").trim().toLowerCase();
				itemB = (b.lookupValue || "").trim().toLowerCase();
				break;
			case "region":
				itemA = (a.lookupValue || "").trim().toLowerCase();
				itemB = (b.lookupValue || "").trim().toLowerCase();
				break;
			case "events":
				itemA = (a.events || "").trim().toLowerCase();
				itemB = (b.events || "").trim().toLowerCase();
				break;
			case "type":
				itemA = (a.type || "").trim().toLowerCase();
				itemB = (b.type || "").trim().toLowerCase();
				break;
			case "languages":
				itemA = (a.languages || "").trim().toLowerCase();
				itemB = (b.languages || "").trim().toLowerCase();
				break;
			case "tournament_type":
				itemA = (a.tournament_type || "").trim().toLowerCase();
				itemB = (b.tournament_type || "").trim().toLowerCase();
				break;
			case "tournament_special_characterisctics":
				itemA = (a.tournament_special_characterisctics || "")
					.trim()
					.toLowerCase();
				itemB = (b.tournament_special_characterisctics || "")
					.trim()
					.toLowerCase();
				break;
			case "subType":
				itemA = (a.subType || "").trim().toLowerCase();
				itemB = (b.subType || "").trim().toLowerCase();
				break;
			case "platform":
				itemA = (a.lookupValue || "").trim().toLowerCase();
				itemB = (b.lookupValue || "").trim().toLowerCase();
				break;
			// Add more cases for other models if needed
			default:
				// Default to sorting by 'name' if the model is not recognized
				itemA = (a.name || "").trim().toLowerCase();
				itemB = (b.name || "").trim().toLowerCase();
		}
		if (itemA < itemB) return -1;
		if (itemA > itemB) return 1;
		return 0;
	});
};

export default sortData;
