import { requestGet } from "../utils/dva16";

import {
	NGame,
	EGet,
	EGetCollect,
	EGetOne,
	EGetDetail,
	RSetState,
	EGetType,
	EGeTPage,
	ETrend,
} from "./contants";

const Game = {
	namespace: NGame,
	state: {
		loading: false,
		GameList: null,
		gameTitle: null,
		GameDetail: null,
		gameSearch: null,
		gamePage: null,
		trendingGames: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("v2/game", payload);
			reducer(RSetState, { GameList: res, loading: false });
		},
		async [EGetType]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("gameSearch/v2", payload);
			reducer(RSetState, { gameSearch: res });
		},
		async [EGetCollect](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("gameTitle/v2", payload);
			reducer(RSetState, { gameTitle: res });
		},
		async [EGetDetail](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("gameTitle/v2", payload);
			reducer(RSetState, { gameTitle: res });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet(`v2/game/${payload?.id}`, payload);
			reducer(RSetState, { GameDetail: res, loading: false });
		},
		async [EGeTPage]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = payload.page;
			reducer(RSetState, { gamePage: res });
		},
		async [ETrend]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("trending-games", payload);
			reducer(RSetState, { trendingGames: res, loading: false });
		},
	},
};
export default Game;
