const E = {
	mock: {
		GamePage: {
			gametag: ["Genre", "PC/Mobile", "Segament", "Approved"],
			ISBN: "ISBN 978-7-498-09367-7",
			baiduIndex: 5673145,
			download: 12345442,
			gamer: 543423,
			revenues: 65464223,
			releaseDate: "2021-06-12",
			approvalDate: "2021-08-12",
		},
	},

	status: {
		0: "Disable",
		1: "Usable",
	},
	operate: {
		Share: "Share",
		Download: "Download",
	},
	Platform: {
		Web: null,
		Mobile: null,
		Admin: null,
	},
	newsStatus: {
		Deleted: "Deleted",
		Pending: "Pending Approval",
		Draft: "Draft",
		Rejected: "Rejected",
		Published: "Published",
	},
	userType: {
		All: "All",
		FreeTrail: "Free trail",
		Admin: "Admin",
		Paid: "Paid",
	},
	mainRegion: {
		EastAsia: "East Asia",
		China: "China",
		Global: "Global",
		India: "India",
		MENA: "MENA",
		SEA: "SEA",
	},
	mainEnum: [
		{ text: "All" },
		{ text: "China" },
		{ text: "East Asia" },
		{ text: "Global" },
		{ text: "India" },
		{ text: "MENA" },
		{ text: "SEA" },
	],
	pageType: {
		ChinaStreaming: "ChinaStreaming",
		Esport: "Esport",
		Finacial: "Finacial",
		InsightsFeed: "InsightsFeed",
	},

	copyright: {
		All: "All",
		Import: "Import",
		Domestic: "Domestic",
	},
	IPOEvents: {
		Investment: "Investment",
		Trade: "Trade",
	},
	IPORounds: {
		StrategyInvestment: "Strategy Investment",
		stake: "100% stake",
	},
	priceUnit: {
		RMB: 1,
		JPY: 18,
		USD: 7,
	},
	revenueType: {
		mobileRevenue: "mobileRevenue",
		pcRevenue: "pcRevenue",
		overseaRevenue: "overseaRevenue",
		consoleRevenue: "consoleRevenue",
		domesticRevenue: "domesticRevenue",
	},
	platformGroupType: {
		PC: "PC",
		Mobile: "Mobile",
		Others: "Others",
	},
};

export default E;
