import React, { useState, useEffect } from "react";
import "moment/locale/en-au";
import { Row, Col, Table, Spin, Divider, Collapse } from "antd";
import { htmlEncode } from "@/utils/utils";
import { effect, useStore } from "../../../utils/dva16";
import { customizeRenderEmpty } from "../../../utils/utils";
import { NMarket, EGet, MNotes, NLogin } from "../../../models/contants";
import { priceSplitter } from "../../../utils/utils";
import CustomEmpty from "@/utils/CustomEmpty";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import { useMediaQuery } from "react-responsive";
export default (props) => {
	const { Panel } = Collapse;
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const { marketList, loading } = useStore(NMarket);
	const { marketNotes } = useStore(MNotes);
	const [searchValues, setSearchValues] = useState({});
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};

	const columns = [
		// {
		// 	title: "No",
		// 	dataIndex: "id",
		// 	key: "id",
		// 	sortOrder: sortState?.field == "id" && sortState?.order,
		// 	sorter: (a, b) => a?.id - b?.id,
		// 	showSorterTooltip: false, // Hide the default sorter tooltip
		// },
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
			showSorterTooltip: false, // Hide the default sorter tooltip
			sortOrder: sortState?.field == "country" && sortState?.order,
			sorter: (a, b) => a?.country?.localeCompare(b?.country),
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			showSorterTooltip: false, // Hide the default sorter tooltip
			sortOrder: sortState?.field == "region" && sortState?.order,
			sorter: (a, b) => a?.region?.localeCompare(b?.region),
		},
		{
			title: "Year",
			dataIndex: "year",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "year",
			sortOrder: sortState?.field == "year" && sortState?.order,
			sorter: (a, b) => {
				const dateA = new Date(a?.year);
				const dateB = new Date(b?.year);
				return dateA - dateB;
			},
		},
		{
			title: "GDP",
			dataIndex: "gdp",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "gdp",
			sortOrder: sortState?.field == "gdp" && sortState?.order,
			sorter: (a, b) => Number(a?.gdp) - Number(b?.gdp),

			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.gdp)}</span>
					</>
				);
			},
		},
		{
			title: "GDP per Capita",
			dataIndex: "gdpPerCapita",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "gdpPerCapita",
			sortOrder: sortState?.field == "gdpPerCapita" && sortState?.order,
			sorter: (a, b) => Number(a?.gdpPerCapita) - Number(b?.gdpPerCapita),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.gdpPerCapita)}</span>
					</>
				);
			},
		},
		{
			title: "Total Population",
			dataIndex: "totalPopulation",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "totalPopulation",
			sortOrder: sortState?.field == "totalPopulation" && sortState?.order,
			sorter: (a, b) => Number(a?.totalPopulation) - Number(b?.totalPopulation),

			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.totalPopulation)}</span>
					</>
				);
			},
		},
		{
			title: "Internet Users",
			dataIndex: "internetUsers",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "internetUsers",
			sortOrder: sortState?.field == "internetUsers" && sortState?.order,
			sorter: (a, b) => Number(a?.internetUsers) - Number(b?.internetUsers),

			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.internetUsers)}</span>
					</>
				);
			},
		},
		{
			title: "Smartphone Users",
			dataIndex: "smartphoneUsers",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "smartphoneUsers",
			sortOrder: sortState?.field == "smartphoneUsers" && sortState?.order,
			sorter: (a, b) => Number(a?.smartphoneUsers) - Number(b?.smartphoneUsers),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.smartphoneUsers)}</span>
					</>
				);
			},
		},
		{
			title: "PC Gamers",
			dataIndex: "pcGamers",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "pcGamers",
			sortOrder: sortState?.field == "pcGamers" && sortState?.order,
			sorter: (a, b) => Number(a?.pcGamers) - Number(b?.pcGamers),

			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.pcGamers)}</span>
					</>
				);
			},
		},
		{
			title: "Mobile Gamers",
			dataIndex: "mobileGamers",
			key: "mobileGamers",
			showSorterTooltip: false, // Hide the default sorter tooltip
			sortOrder: sortState?.field == "mobileGamers" && sortState?.order,
			sorter: (a, b) => Number(a?.mobileGamers) - Number(b?.mobileGamers),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.mobileGamers)}</span>
					</>
				);
			},
		},
		{
			title: "Console Gamers",
			dataIndex: "consoleGamers",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "consoleGamers",
			sortOrder: sortState?.field == "consoleGamers" && sortState?.order,
			sorter: (a, b) => Number(a?.consoleGamers) - Number(b?.consoleGamers),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.consoleGamers)}</span>
					</>
				);
			},
		},
		{
			title: "Total Gamers",
			dataIndex: "totalGamers",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "totalGamers",
			sortOrder: sortState?.field == "totalGamers" && sortState?.order,
			sorter: (a, b) => Number(a?.totalGamers) - Number(b?.totalGamers),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.totalGamers)}</span>
					</>
				);
			},
		},
		{
			title: "PC Games Revenue",
			dataIndex: "pcGamesRevenue",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "pcGamesRevenue",
			sortOrder: sortState?.field == "pcGamesRevenue" && sortState?.order,
			sorter: (a, b) => Number(a?.pcGamesRevenue) - Number(b?.pcGamesRevenue),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.pcGamesRevenue)}</span>
					</>
				);
			},
		},
		{
			title: "Mobile Games Revenue",
			dataIndex: "mobileGamesRevenue",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "mobileGamesRevenue",
			sortOrder: sortState?.field == "mobileGamesRevenue" && sortState?.order,
			sorter: (a, b) =>
				Number(a?.mobileGamesRevenue) - Number(b?.mobileGamesRevenue),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.mobileGamesRevenue)}</span>
					</>
				);
			},
		},
		{
			title: "Console Games Revenue",
			dataIndex: "consoleGamesRevenue",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "consoleGamesRevenue",
			sortOrder: sortState?.field == "consoleGamesRevenue" && sortState?.order,
			sorter: (a, b) =>
				Number(a?.consoleGamesRevenue) - Number(b?.consoleGamesRevenue),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.consoleGamesRevenue)}</span>
					</>
				);
			},
		},
		{
			title: "Total Revenue",
			dataIndex: "totalRevenue",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "totalRevenue",
			sortOrder: sortState?.field == "totalRevenue" && sortState?.order,
			sorter: (a, b) => Number(a?.totalRevenue) - Number(b?.totalRevenue),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.totalRevenue)}</span>
					</>
				);
			},
		},
		{
			title: "Esports Revenues",
			showSorterTooltip: false, // Hide the default sorter tooltip
			dataIndex: "esportsRevenues",
			key: "esportsRevenues",
			sortOrder: sortState?.field == "esportsRevenues" && sortState?.order,
			sorter: (a, b) => Number(a?.esportsRevenues) - Number(b?.esportsRevenues),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.esportsRevenues)}</span>
					</>
				);
			},
		},
	];
	const paginationLocale = {
		items_per_page: "per page",
	};
	useEffect(() => {
		effect(NMarket, EGet, { page: 1, perPage: 1000 });
		effect(MNotes, EGet);
		return () => {};
	}, []);
	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};
	const onPageChange = (page, pageSize, sort) => {
		if (page && pageSize) {
			effect(NMarket, EGet, {
				page: page,
				perPage: pageSize,
				field: sortState && sortState?.field,
				isDesc: (sortState && sortState?.order) || null,
			});
		}
	};
	return (
		<div>
			<Spin spinning={loading} size="large">
				<div className="CompanyBox">
					<Row className="RowBox mt30">
						<Col span={24}>
							{userInfo?.type == "Client" ? (
								currentSubscribe?.module?.hasOwnProperty("MarketModel") &&
								currentSubscribe?.module["MarketModel"]?.length > 0 ? (
									<>
										<Table
											locale={{ emptyText: CustomEmpty }}
											scroll={{ x: 3200, y: 600 }} // Set the desired height here
											dataSource={marketList?.data || []}
											columns={columns}
											className="CompanyTableBox"
											pagination={false}
											onChange={(pagination, filter, sort) => {
												setSortState(sort);
												effect(NMarket, EGet, {
													...searchValues,
													page: pagination?.current,
													perPage: pagination?.pageSize,
													field: sort?.column && sort?.field,
													isDesc:
														(sort?.column &&
															sort?.order &&
															sortType[sort?.order]) ||
														null,
												});
											}}
										/>
										<Divider
											style={{
												borderWidth: "2px",
												borderColor: "rgb(219 218 218)",
												margin: "25px 0",
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: marketNotes && marketNotes[0]?.note,
											}}
										/>
									</>
								) : (
									customizeRenderEmpty()
								) // Provide a fallback, like `null`, instead of an empty fragment.
							) : (
								<>
									<>
										<Table
											locale={{ emptyText: CustomEmpty }}
											scroll={{ x: 3200, y: 600 }} // Set the desired height here
											dataSource={marketList?.data || []}
											columns={columns}
											className="CompanyTableBox"
											pagination={false}
											onChange={(pagination, filter, sort) => {
												setSortState(sort);
												effect(NMarket, EGet, {
													...searchValues,
													page: pagination?.current,
													perPage: pagination?.pageSize,
													field: sort?.column && sort?.field,
													isDesc:
														(sort?.column &&
															sort?.order &&
															sortType[sort?.order]) ||
														null,
												});
											}}
										/>
										<Divider
											style={{
												borderWidth: "2px",
												borderColor: "rgb(219 218 218)",
												margin: "25px 0",
											}}
										/>
										<div
											className="marketNotes"
											dangerouslySetInnerHTML={{
												__html: marketNotes && marketNotes[0]?.note,
											}}
										/>
									</>
								</>
							)}
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
