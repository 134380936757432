import {
	NEarnings,
	EGet,
	EPost,
	EPostAll,
	EPut,
	EPutRevenue,
	EDelete,
	RSetState,
	EGetOne,
	EGetRevenueTitle,
	EGetRevenuePageAnalysis,
} from "./contants";
import {
	requestGet,
	requestPost,
	requestPut,
	requestDelete,
} from "../utils/dva16";

const earnings = {
	namespace: NEarnings,
	state: {
		loading: false,
		EarningsList: null,
		EarningsTitle: null,
		EarningsDetail: null,
		EarningsDetailTitle: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("revenuePage", payload);
			reducer(RSetState, { EarningsList: res, loading: false });
		},
		async [EGetRevenuePageAnalysis](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet(`revenuePageAnalysis`, payload);
			reducer(RSetState, { EarningsTitle: res });
		},
		async [EPost]({ payload, callback }, { call, reducer, select, effect }) {
			await requestGet(`revenue`, payload);
			callback && callback();
		},
		async [EPostAll]({ payload, callback }, { call, reducer, select, effect }) {
			await requestGet(`revenueOverAll`, payload);
			callback && callback();
		},
		async [EPut]({ payload, callback }, { call, reducer, select, effect }) {
			await requestGet(`revenueOverAll/${payload?.id}`, payload);
			callback && callback();
		},
		async [EPutRevenue](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestGet(`revenue/${payload?.id}`, payload);
			callback && callback();
		},
		async [EDelete]({ payload, callback }, { call, reducer, select, effect }) {
			await requestDelete(`revenueOverAll/${payload?.id}`, payload);
			callback && callback();
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet(`revenue`, payload);
			reducer(RSetState, { EarningsDetail: res });
		},
		async [EGetRevenueTitle](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet(`revenueTitle`, payload);
			reducer(RSetState, { EarningsDetailTitle: res });
		},
	},
};
export default earnings;
