import { requestGet } from "@/utils/dva16";
import { EGet, NTournamentType, RSetState } from "./contants";

export default {
	namespace: NTournamentType,
	state: {
		tournamentTypeList: null,
		load: false,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { load: true });
			let res = await requestGet("tournament-type", payload);
			reducer(RSetState, { tournamentTypeList: res, load: false });
		},
	},
};
