import { requestGet } from "../utils/dva16";
import {
	NCommon,
	EGetAgencies,
	EGetSponsor,
	RSetState,
	EGetActivetType,
} from "./contants";

const Advert = {
	namespace: NCommon,
	state: {
		sponsorList: null,
		agencyList: null,
		activityTypeList: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGetSponsor](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			payload.category = "Company";
			let res = await requestGet("organizations", payload);
			reducer(RSetState, { sponsorList: res });
		},
		async [EGetAgencies](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			payload.category = "Regulatory";
			let res = await requestGet("organizations", payload);
			reducer(RSetState, { agencyList: res });
		},
		async [EGetActivetType](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("settingActivity", payload);
			reducer(RSetState, { activityTypeList: res?.data });
		},
	},
};
export default Advert;
