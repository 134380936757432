import { EGet, env, RSetState } from "./contants";
import { requestGet } from "@/utils/dva16";
import { Config } from "../config";
export default {
	namespace: env,
	state: {
		currentEnivronment: null,

		load: false,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { load: true });
			let res = await requestGet(
				Config?.SERVER_HOME?.replace("client/v1/", env),
				payload
			);
			reducer(RSetState, { currentEnivronment: res, load: false });
		},
	},
};
