import {
	requestGet,
	requestPost,
	requestPut,
	requestDelete,
} from "../utils/dva16";

import {
	NBlog,
	EGet,
	EGetOne,
	EPost,
	EPut,
	RSetState,
	EDelete,
	EGetTop,
} from "./contants";

const Blog = {
	namespace: NBlog,
	state: {
		BlogList: null,
		detailList: null,
		loading: null,
		topBlogList: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("news", payload);
			reducer(RSetState, { BlogList: res, loading: false });
			callback && callback(res);
		},
		async [EGetTop]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("news", payload);
			reducer(RSetState, { topBlogList: res });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet(`news/${payload?.id}`, payload);
			reducer(RSetState, { detailList: res, loading: false });
		},
		async [EPost]({ payload, callback }, { call, reducer, select, effect }) {
			await requestPost("reports", payload);
			callback && callback();
		},
		async [EPut]({ payload, callback }, { call, reducer, select, effect }) {
			await requestPut(`reports/${payload?.id}`, payload);
			callback && callback();
		},
		async [EDelete]({ payload, callback }, { call, reducer, select, effect }) {
			await requestDelete(`reports/${payload?.id}`, payload);
			callback && callback();
		},
	},
};
export default Blog;
