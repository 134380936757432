import React, { useEffect } from "react";
import { Modal, Row, Col, Input, Form, Button } from "antd";

export default (props) => {
	const [editForm] = Form.useForm();
	const { onOk, visible } = props;
	useEffect(() => {
		editForm.resetFields();
	}, [visible]);
	const onOks = async () => {
		try {
			await editForm.validateFields();
			let values = editForm.getFieldValue();
			onOk && onOk(values);
		} catch (e) {}
	};
	return (
		<Modal {...props}>
			<Row justify="center">
				<Col span={24} className="top_tip">
					Please retrieve the password through email verification
				</Col>
			</Row>
			<Form
				form={editForm}
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 14 }}
				className="form_Email"
			>
				<Form.Item
					label="Username"
					name="username"
					rules={[
						{ required: true, message: "Please input your username!" },
						// {
						// 	type: "email",
						// 	message: "The input is not valid E-mail!",
						// },
					]}
				>
					<Input placeholder="Input here Username" />
				</Form.Item>
			</Form>
			<Row style={{ marginTop: 28 }}>
				<Col style={{ margin: "0 auto" }}>
					<Button
						className="btn_1"
						htmlType="submit"
						onClick={() => {
							onOks();
						}}
					>
						SEND PASSWORD RESET
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};
