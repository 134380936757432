import { requestGet } from "../utils/dva16";
import {
	NRegion,
	EGet,
	RSetState,
	EGetArea,
	EGetCommonArea,
	EGetRegion,
} from "./contants";

const Region = {
	namespace: NRegion,
	state: {
		loading: false,
		areaList: null,
		commonAreaList: null,
		regionList: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGetArea]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("region", payload);
			reducer(RSetState, { areaList: res, loading: false });
		},
		async [EGetRegion](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { load: true });
			let res = await requestGet("getRegions", payload);
			reducer(RSetState, { load: false, regionList: res });
		},
		async [EGetCommonArea](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("region", payload);
			reducer(RSetState, { commonAreaList: res });
		},
	},
};
export default Region;
